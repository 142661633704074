import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    name: '',
    value: 0
}
export const paymentPriceSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        changeValue: (state, action) => {
            state.name = action.payload.name
            state.value = action.payload.value
        }
    }
})

export const {changeValue } = paymentPriceSlice.actions;


export default paymentPriceSlice.reducer;
