import "./cardHighlight.css";
import {
  AiOutlineDollarCircle,
  AiOutlineShopping,
  AiOutlineUser,
  AiOutlineSkin,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import AuthService from "../../services/auth.service";

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};

const CardHighlight = ({url}) => {
  const currentUser = AuthService.getCurrentUser();


  const [data, setData] = useState();
  useEffect(() => {
    const fetchApi = async () => {
      try {
        const res = await axios.get(url);
        setData(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchApi();
  }, [url]);

  return (
    <div className="container-card-highlight">
      <div className={currentUser?.role === 1 ? "d-grid g-4 gap-25px" : "d-grid g-3 gap-25px"}>
        <div className="card-highlight">
          <p>ยอดขายทั้งหมด</p>
          <span>฿{formatNumber(data && data[4][0]["total"])}</span>
          <div className="d-flex al-end jc-between">
            <Link to="/admin/chart" className="primary-color link">
              ดูเพิ่มเติม
            </Link>
            <div
              className="icon d-flex al-center jc-center"
              style={{ backgroundColor: "#D1FAE6" }}
            >
              <AiOutlineDollarCircle style={{ color: "#34C38F" }} />
            </div>
          </div>
        </div>
        <div className="card-highlight">
          <p>รายการคำสั่งซื้อ</p>
          <span>+{formatNumber(data && data[3][0]["orders"])}</span>
          <div className="d-flex al-end jc-between">
            <Link to="/admin/orders" className="primary-color link">
              ดูเพิ่มเติม
            </Link>
            <div
              className="icon d-flex al-center jc-center"
              style={{ backgroundColor: "#EBF2FD" }}
            >
              <AiOutlineShopping style={{ color: "#5D5FEF" }} />
            </div>
          </div>
        </div>
        <div className="card-highlight">
          <p>ลูกค้าทั้งหมด</p>
          <span>{formatNumber(data && data[2][0]["users"])}</span>
          <div className="d-flex al-end jc-between">
            <Link to="/admin/users" className="primary-color link">
              ดูเพิ่มเติม
            </Link>
            <div
              className="icon d-flex al-center jc-center"
              style={{ backgroundColor: "#FCF1DE" }}
            >
              <AiOutlineUser style={{ color: "#F7B84B" }} />
            </div>
          </div>
        </div>
        {currentUser?.role === 1 ? (
          <div className="card-highlight">
            <p>สินค้าทั้งหมด</p>
            <span>{formatNumber(data && data[0][0]["product"])}</span>
            <div className="d-flex al-end jc-between">
              <Link to="/admin/products" className="primary-color link">
                ดูเพิ่มเติม
              </Link>
              <div
                className="icon d-flex al-center jc-center"
                style={{ backgroundColor: "#DDDFE1" }}
              >
                <AiOutlineSkin style={{ color: "#324B76" }} />
              </div>
            </div>
          </div>
        ) : ''}

      </div>
    </div>
  );
};

export default CardHighlight;
