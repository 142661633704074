import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pictureUrl: '',
    displayName: '',
    userId: '',
};


export const customerPriceSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        addCustomer: (state, action) => {
            state.pictureUrl = action.payload.pictureUrl
            state.displayName = action.payload.displayName
            state.userId = action.payload.userId
        }
    }
})


export const { addCustomer } = customerPriceSlice.actions

export default customerPriceSlice.reducer