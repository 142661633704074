import './formComtact.css'
const FormContact = () => {
    return (
        <div className='form-contact'>
            <p style={{ marginBottom: '.5rem' }}><b>ส่งอีเมลหาเรา</b></p>
            <label htmlFor="name">ชื่อ - นามสกุล</label>
            <input className='input' type="text" id="name" />
            <label htmlFor="tel">เบอร์มือถือ</label>
            <input className='input' type="tel" id="tel" />
            <label htmlFor="email">อีเมล</label>
            <input className='input' type="email" id="email" />
            <label htmlFor="heading">หัวข้อเรื่อง</label>
            <input className='input' type="text" id="heading" />
            <label htmlFor="longtext">รายละเอียด</label>
            <textarea id="longtext" cols="30" rows="10"></textarea>

            <button className='primary-button'>ส่งคำสั่งซื้อ</button>
        </div>
    )
}

export default FormContact