import './nav.css'
import { NavLink } from "react-router-dom"
import { useEffect, useState } from 'react'

const Nav = () => {
    const [screenSize, setScreenSize] = useState(0);
    useEffect(() => {
        setScreenSize(window.innerWidth)
    }, [])
    return (
        <>
            {screenSize > 767 ? (
                <nav className='user main d-flex al-center jc-center w-100'>
                    <ul className='d-flex al-center jc-around'>
                        <li><NavLink className={({ isActive }) =>
                            isActive ? 'list active' : 'list'
                        } to="/">หน้าแรก</NavLink></li>
                        <li><NavLink className="list" to="/products">สินค้า</NavLink></li>
                        {/* <li><NavLink className="list" to="/how-to-order">วิธีการสั่งซื้อ</NavLink></li> */}
                        <li><NavLink className="list" to="/review">รีวิวจากลูกค้า</NavLink></li>
                        {/* <li><NavLink className="list" to="/payment">การชำะเงิน</NavLink></li>
                        <li><NavLink className="list" to="/contact-us">ติดต่อเรา</NavLink></li> */}
                    </ul>
                </nav>
            ) : ''}
        </>
    )
}

export default Nav