import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const login = (payload) => {
  return axios
    .post(API_URL + "/auth/login", payload)
    .then((response) => {
      if (response.data.username) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  return axios.post(API_URL + "/auth/logout").then((response) => {
    return response;
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
}

export default AuthService;