import { BsThreeDots } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Switch } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";

const FlashDealAdmin = () => {
  const handleClickActionTable = (e) => {
    const parent = e.target.closest(".actions");
    const dropdown = parent.querySelector(".dropdown");

    dropdown.style.display === "none" || dropdown.style.display === ""
      ? (dropdown.style.display = "block")
      : (dropdown.style.display = "none");
  };

  const onChangeToggle = (checked, id) => {
    let status;
    checked ? (status = 1) : (status = 0);
    axios.put("/product/onsale/" + id, {
      status: status,
    });
  };

  // filter
  const [filterCate, setFilterCate] = useState("");

  // category
  const [cate, setCate] = useState([]);
  useEffect(() => {
    const fetchCate = async () => {
      try {
        const res = await axios.get("/category/all");
        setCate(res?.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCate();
  }, []);

  const [popupCateFlash, setPopupCateFlash] = useState(false);
  const [textOptionCateFlash, setTextOptionCateFlash] =
    useState("เลือกหมวดหมู่");

  const [products, setProdcuts] = useState([]);
  useEffect(() => {
    let url = "";
    filterCate !== ""
      ? (url += `/product/onsale?&cateId=${filterCate}`)
      : (url += `/product/onsale`);

    const fetchProducts = async () => {
      try {
        const res = await axios.get(url);
        setProdcuts(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchProducts();
  }, [filterCate]);

  return (
    <>
      {/* Dropdown */}
      <div
        className="d-flex jc-between al-center gap-1rem"
        style={{ marginTop: "3rem" }}
      >
        <div className="d-flex al-center jc-start gap-1rem">
          {/* cate */}
          <div
            className="d-flex al-center jc-end"
            style={{ position: "relative" }}
          >
            <div
              className="d-flex al-center jc-between option ml-0"
              onClick={() => {
                setPopupCateFlash(true);
              }}
            >
              {textOptionCateFlash} <RiArrowDropDownLine />
            </div>
            <div
              className="dropdown"
              style={{ display: popupCateFlash ? "block" : "none" }}
              onClick={(e) => {
                setPopupCateFlash(false);
              }}
            >
              <ul
                onClick={(e) => {
                  setTextOptionCateFlash(e.target.innerText);
                }}
              >
                <li
                  className={
                    textOptionCateFlash === "เลือกหมวดหมู่"
                      ? "link active"
                      : "link"
                  }
                  onClick={() => {
                    setFilterCate("");
                  }}
                >
                  เลือกหมวดหมู่
                </li>
                {cate.length > 0
                  ? cate.map((c) => (
                      <li
                        key={c.id}
                        className={
                          textOptionCateFlash === `${c.name}`
                            ? "link active"
                            : "link"
                        }
                        onClick={() => {
                          setFilterCate(c.id);
                        }}
                      >
                        {c.name}
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="table">
        <p className="title">ดีลฟ้าผ่า</p>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>รูปภาพ</th>
              <th>ชื่อสินค้า</th>
              <th>หมวดหมู่สินค้า</th>
              <th>ราคาสินค้า</th>
              <th>ราคาเซลล์</th>
              <th>ดีลฟ้าผ่า</th>
              <th>คำสั่ง</th>
            </tr>
          </thead>
          <tbody>
            {products.length > 0 ? (
              products.map((p) => (
                <tr key={p.id}>
                  <td>{p.id}</td>
                  <td>
                    <div className="d-flex al-center jc-start">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL + `/${p?.thumbnail}`
                        }
                        alt=""
                      />
                    </div>
                  </td>
                  <td>{p?.name}</td>
                  <td>{p?.cateId !== 0 ? p?.category?.name : "-"}</td>
                  <td>{p?.price}฿</td>
                  <td>{p?.discount}฿</td>
                  <td>
                    <Switch
                      defaultChecked={p.flashSale === 1 ? true : false}
                      onChange={(e) => {
                        onChangeToggle(e, p.id);
                      }}
                    />
                  </td>
                  <td>
                    <div className="actions">
                      <BsThreeDots
                        onClick={(e) => {
                          handleClickActionTable(e);
                        }}
                      />
                      <div className="dropdown">
                        <ul
                          onClick={(e) => {
                            handleClickActionTable(e);
                          }}
                        >
                          <li>
                            <Link
                              to={"/admin/update-product/" + p.id}
                              className="link"
                            >
                              แก้ไข
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FlashDealAdmin;
