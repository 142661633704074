import React, { useEffect, useState } from "react";
import DealOfDay from "../../components/dealOfDay/DealOfDay";
import { FcFlashOn } from "react-icons/fc";
import FlashDeal from "../../components/flashDeal/FlashDeal";
import CardProduct from "../../components/cardProduct/CardProduct";
import axios from "axios";

const Promotions = () => {
  // products
  const [productsDeal, setProductsDeal] = useState([]);
  const [productsFlashSale, setProductsFlashSale] = useState([]);
  const [productsOnSale, setProductsOnSale] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const resDeal = await axios.get("/deal/public/all");
        const resFlash = await axios.get("/product/flashSale");
        const resSale = await axios.get("/product/onsale");
        setProductsDeal(resDeal.data);
        setProductsFlashSale(resFlash.data);
        setProductsOnSale(resSale.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="promotions">
      <p className="u-title">Deal Of The Day</p>
      {productsDeal && productsDeal.length > 0
        ? productsDeal.map((deal) => <DealOfDay data={deal} />)
        : ""}

      <p className="u-title mt-2">
        ดีลฟ้าผ่า <FcFlashOn />
        <FcFlashOn style={{ fontSize: "1.8rem", marginLeft: "-10px" }} />
      </p>

      {productsFlashSale && productsFlashSale.length > 0
        ? productsFlashSale.map((flashSale) => <FlashDeal data={flashSale} />)
        : ""}

      <p className="u-title mt-2">กำลังลดราคา</p>
      <div className="d-grid g-4 md-g-2 gap-1rem ">
        {productsOnSale && productsOnSale.length > 0
          ? productsOnSale.map((onSale) => <CardProduct data={onSale} />)
          : ""}
      </div>
    </div>
  );
};

export default Promotions;
