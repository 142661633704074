import './layoutAdmin.css'
import HeaderAdmin from '../../components/headerAdmin/HeaderAdmin'
import SidebarAdmin from '../../components/sidebarAdmin/SidebarAdmin'
import { useEffect, useState } from 'react'

export const LayoutAdmin = ({ children }) => {
  const [screenSize, setScreenSize] = useState(0)

  useEffect(() => {
    setScreenSize(window.innerWidth)
  }, [])

  return (
    <>
      <HeaderAdmin />
      <main className='admin'>
        {screenSize > 767 ? <SidebarAdmin /> : ''}
        <div className="contents">
          {children}
        </div>
      </main>
    </>
  )
}
