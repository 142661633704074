import React, { useEffect, useState } from 'react'
import { BsThreeDots } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import Pagination from '../../components/pagination/Pagination'
import axios from 'axios'
import Moment from 'react-moment'
import AuthService from '../../services/auth.service'

const Users = () => {

  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();

  // check role
  useEffect(()=>{
    if(currentUser?.role === 3 ){
      navigate("/admin/orders");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentUser?.role])


  const handleClickActionTable = (e) => {
    const parent = e.target.closest('.actions')
    const dropdown = parent.querySelector('.dropdown');

    dropdown.style.display === 'none' || dropdown.style.display === '' ? dropdown.style.display = 'block' : dropdown.style.display = 'none'
  }

  const [customer, setCustomer] = useState([]);
  // Pagination
  const [countPagination, setCountPagination] = useState(0);
  const [activeBtn, setActiveBtn] = useState(1);
  const [maxList, setMaxList] = useState(25);

  useEffect(() => {
    const fetchApi = async () => {
      try {
        let url = '/customer/all'
        url += "?page=" + activeBtn
        url += "&limit=" + maxList
        const res = await axios.get(url);
        setCustomer(res.data[1]?.data)
        setCountPagination(res.data[0]?.pagination);
      } catch (err) {
        console.log(err);
      }
    }
    fetchApi();

  }, [activeBtn, maxList])


  const handleDel = async (id) => {
    const res = await axios.delete('/customer/' + id);
    if (res.status === 200) {
      window.location.reload()
    }
  }


  return (
    <div className='customers'>
      <div className="d-flex jc-start al-center">
        <h2 className="heading">ลูกค้า</h2>
      </div>
      <div className="d-flex jc-end">
        <input type="number" className='list-per-page' onChange={((e) => {
          setMaxList(e.target.value)
          setActiveBtn(1)
        })} placeholder='จำนวนรายการ/หน้า' />
      </div>
      {/* table */}
      <div className="table">
        <p className="title">รายชื่อลูกค้า (903)</p>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>วันที่</th>
              <th>ชื่อลูกค้า</th>
              <th>แก้ไขล่าสุด</th>
              <th>คำสั่ง</th>
            </tr>
          </thead>
          <tbody>
            {customer && customer.length > 0 ? (
              customer.map(user => (
                <tr key={user.id}>
                  <td>{user?.id}</td>
                  <td>{<Moment format="DD-MM-YYYY">{user?.createdAt}</Moment>}</td>
                  <td>
                    <div className="d-flex al-center jc-start gap-small">
                      <img className='border-round' src={user?.pictureUrl} alt="" />
                      <span className='name'>{user?.displayName}</span>
                    </div>
                  </td>
                  <td>{<Moment format="DD-MM-YYYY">{user?.updatedAt}</Moment>}</td>
                  <td>
                    <div className='actions'>
                      <BsThreeDots onClick={(e) => { handleClickActionTable(e) }} />
                      <div className="dropdown">
                        <ul onClick={(e) => { handleClickActionTable(e) }} >
                          <li><Link to={"/admin/user/" + user.id} className='link'>รายละเอียด</Link></li>
                          <li><Link to={'/admin/update-user/' + user.id} className='link'>แก้ไข</Link></li>
                          <li className='link red-color' onClick={() => {
                            if (window.confirm("คุณต้องการลบผู้ใช้รายนี้หรือไม่?")) {
                              handleDel(user.id)
                            };
                          }}>ลบ</li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : <tr></tr>}
          </tbody>
        </table>
      </div>
      <Pagination
        total={countPagination}
        active={activeBtn}
        action={setActiveBtn}
        limit={maxList}
      />
    </div>
  )
}

export default Users