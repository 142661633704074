import { Link } from 'react-router-dom'
import './header.css'

const Header = () => {
  return (
    <header className='user'>
      <Link to='/' className='d-flex al-center jc-center'><img style={{maxWidth: '100px'}} src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/logo.webp'} alt="" /></Link>
    </header>
  )
}

export default Header