import "./addProduct.css";
import { useEffect, useState } from "react";
import { BsCheck } from "react-icons/bs";
import { RiArrowDropDownLine, RiImageAddFill } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";

const AddProduct = () => {
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();
    // check role
    useEffect(()=>{
      if(currentUser?.role !== 1 ){
        navigate("/admin/");
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentUser?.role])

  const [popupCate, setPopupCate] = useState(false);
  const [textOptionCate, setTextOptionCate] = useState("เลือกหมวดหมู่");

  // category
  const [cate, setCate] = useState([]);
  useEffect(() => {
    const fetchCate = async () => {
      try {
        const res = await axios.get("/category/all");
        setCate(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCate();
  }, []);

  // thumbnail
  const [thumbnail, setThumbnail] = useState();
  const thumbnailChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setThumbnail(e.target.files[0]);
    }
  };

  // gallery
  const [gallery, setGallery] = useState([]);
  const [previewGallery, setPreviewGallery] = useState([]);
  const handleChange = (event) => {
    let newFiles = [...gallery, ...event.target.files];
    setGallery(newFiles);
    let preview = [];
    for (let i = 0; i < newFiles.length; i++) {
      let file = newFiles[i];
      let reader = new FileReader();
      reader.onloadend = () => {
        preview.push(reader.result);
        setPreviewGallery(preview);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = (index) => {
    let galleryCopy = [...previewGallery];
    galleryCopy.splice(index, 1);
    setPreviewGallery(galleryCopy);

    let fileGallery = [...gallery];
    fileGallery.splice(index, 1);
    setGallery(fileGallery);
  };

  // data detail product
  const [values, setValues] = useState({
    name: "",
    price: 0,
    discount: "",
    description: "",
    cateId: 0,
    status: 0,
  });
  const handleChangeValue = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleUpload = (status) => {
    let formData = new FormData();
    for (let i = 0; i < gallery.length; i++) {
      formData.append("gallery", gallery[i]);
    }
    formData.append("thumbnail", thumbnail);
    formData.append("name", values.name);
    formData.append("price", values.price);
    formData.append("discount", values.discount);
    formData.append("description", values.description);
    formData.append("cateId", values.cateId);
    formData.append("status", status);

    axios
      .post("/product", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        alert("เพิ่มสินค้าสำเร็จ");
        navigate("/admin/products");
      })
      .catch((err) => {
        alert("เกิดข้อผิดพลาด!");
        console.error(err);
      });
  };

  return (
    <div className="add-product">
      <div className="d-flex jc-between al-center margin-bt-1">
        <h2 className="heading">สินค้า</h2>
        {Number(values.price) >= Number(values.discount) ? (
          <div className="d-flex al-center jc-end gap-small">
            <button
              className="second-button"
              onClick={() => {
                handleUpload(1);
              }}
            >
              <BsCheck /> เผยแพร่
            </button>
            <button
              className="primary-button"
              onClick={() => {
                handleUpload(0);
              }}
            >
              บันทึก
            </button>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="container">
        {/* left */}
        <div className="d-grid g-2 md-g-1 gap-25px">
          <div>
            <p className="title">
              ชื่อสินค้า<span>*</span>
            </p>
            <input
              type="text"
              onChange={handleChangeValue}
              name="name"
              placeholder="กรอกชื่อสินค้า"
            />
            <div className="d-grid g-2 gap-1rem md-g-1">
              <div>
                <p className="title">
                  ราคาสินค้า<span>*</span>
                </p>
                <input
                  type="number"
                  onChange={handleChangeValue}
                  name="price"
                  placeholder="กรอกราคาสินค้า"
                />
              </div>
              <div>
                <p className="title">ลดเหลือ</p>
                <input
                  type="number"
                  style={{
                    color:
                      Number(values.price) < Number(values.discount)
                        ? "#E11D48"
                        : "",
                    outline:
                      Number(values.price) < Number(values.discount)
                        ? "2px solid #E11D48"
                        : "",
                  }}
                  onChange={handleChangeValue}
                  name="discount"
                  placeholder="กรอกราคาสินค้า"
                />
              </div>
            </div>
            <p className="title">รายละเอียดสินค้า</p>
            <textarea
              placeholder="กรอกรายละเอียดสินค้า"
              onChange={handleChangeValue}
              name="description"
            ></textarea>

            <p className="title">หมวดหมู่สินค้า</p>

            <div
              className="d-flex al-center jc-end"
              style={{ position: "relative" }}
            >
              <div
                className="d-flex al-center jc-between option ml-0"
                onClick={() => {
                  setPopupCate(true);
                }}
              >
                {textOptionCate} <RiArrowDropDownLine />
              </div>
              <div
                className="dropdown"
                style={{ display: popupCate ? "block" : "none" }}
                onClick={(e) => {
                  setPopupCate(false);
                }}
              >
                <ul
                  onClick={(e) => {
                    setTextOptionCate(e.target.innerText);
                  }}
                  name="cateId"
                >
                  <li
                    className={
                      textOptionCate === "เลือกหมวดหมู่"
                        ? "link active"
                        : "link"
                    }
                  >
                    เลือกหมวดหมู่
                  </li>
                  {cate.length > 0
                    ? cate.map((c) => (
                        <li
                          key={c.id}
                          onClick={() => {
                            setValues((prev) => ({ ...prev, cateId: c.id }));
                          }}
                          className={
                            textOptionCate === c.name ? "link active" : "link"
                          }
                          cate-filter={c.id}
                        >
                          {c.name}
                        </li>
                      ))
                    : ""}
                </ul>
              </div>
            </div>
          </div>

          {/* right */}
          <div>
            <div>
              <p className="title">
                ภาพสินค้า<span>*</span>
              </p>
              <label
                htmlFor="thumbnail"
                className="d-flex al-center jc-center thumbnail"
              >
                <input
                  accept="image/*"
                  type="file"
                  onChange={thumbnailChange}
                  id="thumbnail"
                  style={{ display: "none" }}
                />
                {thumbnail === undefined ? (
                  <div className="d-flex f-column al-center jc-center">
                    <RiImageAddFill />
                    <small>คลิ๊กเพื่อเพิ่มภาพสินค้า</small>
                  </div>
                ) : (
                  <img
                    src={URL.createObjectURL(thumbnail)}
                    alt="Thumb"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </label>
            </div>
            <div>
              <p className="title">แกลเลอรี่สินค้า</p>
              <label
                htmlFor="gallery"
                className="d-flex al-center jc-center galleries"
              >
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleChange}
                  id="gallery"
                  style={{ display: "none" }}
                  multiple
                />
                <div className="d-flex f-column al-center jc-center">
                  <RiImageAddFill />
                  <small>คลิ๊กเพื่อเพิ่มภาพสินค้า</small>
                </div>
              </label>
            </div>
            <div className="upload-galleries">
              {previewGallery.map((image, index) => (
                <div
                  key={index}
                  className="list d-flex al-center jc-between gap-1rem"
                >
                  <div className="d-flex al-center jc-start gap-1rem">
                    <img src={image} alt="" />
                    <span>{gallery[index].name}</span>
                  </div>
                  <div
                    className="d-flex al-center jc-center border-round icon"
                    onClick={() => handleDelete(index)}
                  >
                    <IoCloseSharp />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
