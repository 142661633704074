import { useEffect, useState } from "react";
import Pagination from "../pagination/Pagination";
// import SearchBox from "../searchBox/SearchBox";
import { BiSearch } from "react-icons/bi";
import "./addDeal.css";
import { RiArrowDropDownLine } from "react-icons/ri";
import axios from "axios";

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};

const AddDeal = ({ show }) => {
  const [popupCate, setPopupCate] = useState(false);
  const [textOptionCate, setTextOptionCate] = useState("เลือกหมวดหมู่");

  // filter
  const [filterCate, setFilterCate] = useState("");
  const [filterText, setFilterText] = useState("");

  // category
  const [cate, setCate] = useState([]);
  useEffect(() => {
    const fetchCate = async () => {
      try {
        const res = await axios.get("/category/all");
        setCate(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCate();
  }, []);

  const [textOptionCateFlash] = useState("เลือกหมวดหมู่");

  // Pagination
  const [countPagination, setCountPagination] = useState(0);
  const [activeBtn, setActiveBtn] = useState(1);

  // products
  const [products, setProducts] = useState([]);
  useEffect(() => {
    let url = "/product/admin?status=";
    filterCate !== "" ? (url += `&cateId=${filterCate}`) : (url += `&cateId=`);
    filterCate !== undefined
      ? (url += `&page=${activeBtn}`)
      : (url += `page=${activeBtn}`);

    const fetchProducts = async () => {
      try {
        const res = await axios.get(url);
        setProducts(res.data[1]?.data);
        setCountPagination(res.data[0]?.pagination);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProducts();
  }, [filterCate, activeBtn]);

  const filterByText = async () => {
    let url = "/product/admin?status=";
    filterCate !== "" ? (url += `&cateId=${filterCate}`) : (url += `&cateId=`);
    filterCate !== undefined
      ? (url += `&page=${activeBtn}`)
      : (url += `page=${activeBtn}`);
    filterText !== "" ? (url += `&textName=${filterText}`) : (url += "");

    try {
      const res = await axios.get(url);
      setProducts(res.data[1]?.data);
      setCountPagination(res.data[0]?.pagination);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddDeal = async (id) => {
    try {
      await axios.post("/deal/" + id);
      alert("เพิ่มสินค้าสำเร็จ");
      window.location.reload();
    } catch (err) {
      alert("เกิดข้อผิดพลาด");
      console.log(err);
    }
  };

  return (
    <>
      {show ? (
        <div className="f-flex al-center jc-center add-deal">
          <p className="title">เพิ่มสินค้า</p>
          <div className="d-flex al-center jc-between">
            <div className="d-flex al-center jc-start input search-box">
              <BiSearch />
              <input
                type="text"
                placeholder="ค้นหา..."
                onChange={(e) => {
                  setFilterText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    setActiveBtn(1);
                    filterByText();
                  }
                }}
              />
            </div>
            {/* <SearchBox /> */}
            <div
              className="d-flex al-center jc-end"
              style={{ position: "relative" }}
            >
              <div
                className="d-flex al-center jc-between option ml-0"
                onClick={() => {
                  setPopupCate(true);
                }}
              >
                {textOptionCate} <RiArrowDropDownLine />
              </div>
              <div
                className="dropdown"
                style={{ display: popupCate ? "block" : "none" }}
                onClick={(e) => {
                  setPopupCate(false);
                }}
              >
                <ul
                  onClick={(e) => {
                    setTextOptionCate(e.target.innerText);
                  }}
                >
                  <li
                    className={
                      textOptionCateFlash === "เลือกหมวดหมู่"
                        ? "link active"
                        : "link"
                    }
                    onClick={() => {
                      setFilterCate("");
                      setActiveBtn(1);
                    }}
                  >
                    เลือกหมวดหมู่
                  </li>
                  {cate.length > 0
                    ? cate.map((c) => (
                        <li
                          key={c.id}
                          className={
                            textOptionCateFlash === `${c.name}`
                              ? "link active"
                              : "link"
                          }
                          onClick={() => {
                            setFilterCate(c.id);
                            setActiveBtn(1);
                          }}
                        >
                          {c.name}
                        </li>
                      ))
                    : ""}
                </ul>
              </div>
            </div>
          </div>

          {/* table */}
          <div className="table">
            <p className="title">รายการสินค้า</p>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>รูปภาพ</th>
                  <th>ชื่อสินค้า</th>
                  <th>หมวดหมู่สินค้า</th>
                  <th>ราคา</th>
                  <th>คำสั่ง</th>
                </tr>
              </thead>
              <tbody>
                {products.length > 0 ? (
                  products.map((p) => (
                    <tr key={p.id}>
                      <td>{p.id}</td>
                      <td>
                        <div className="d-flex al-center jc-start">
                          <img
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              `/${p?.thumbnail}`
                            }
                            alt=""
                          />
                        </div>
                      </td>
                      <td>{p?.name}</td>
                      <td>
                        {p?.category?.name !== undefined
                          ? p?.category?.name
                          : "-"}
                      </td>
                      <td>{formatNumber(p?.price)}฿</td>
                      <td>
                        <button
                          className="primary-button"
                          onClick={() => {
                            handleAddDeal(p.id);
                          }}
                        >
                          เพิ่มสินค้า
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            total={countPagination}
            active={activeBtn}
            action={setActiveBtn}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AddDeal;
