import './payment.css'

const Payment = () => {
  return (
    <div className='payment'>
      <p className="u-title">ช่องทางการชำระเงิน</p>
      <div className="d-grid g-3 gap-25px mt-2 md-g-1">
        <div className="d-flex al-center jc-center f-column card">
          <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/kbank.png'} alt="" />
          <p>ชื่อบัญชี : XXXXXXX  XXX</p>
          <p>เลขที่บัญชี : xxx-1-1-00000-x</p>
        </div>
        <div className="d-flex al-center jc-center f-column card">
          <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/krungsri.png'} alt="" />
          <p>ชื่อบัญชี : XXXXXXX  XXX</p>
          <p>เลขที่บัญชี : xxx-1-1-00000-x</p>
        </div>
        <div className="d-flex al-center jc-center f-column card">
          <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/krungthai.png'} alt="" />
          <p>ชื่อบัญชี : XXXXXXX  XXX</p>
          <p>เลขที่บัญชี : xxx-1-1-00000-x</p>
        </div>
      </div>
      <p style={{textAlign: 'center', marginTop: '2rem'}}>*** โอนแล้วอย่าลืมแจ้งสลิปโอนนะครับ *** </p>
    </div>
  )
}

export default Payment