import './navAdmin.css'
import { AiOutlinePieChart, AiOutlineLineChart, AiOutlineFileImage, AiOutlineShop, AiOutlineApartment, AiOutlineSolution, AiOutlineUser, AiOutlineFire } from 'react-icons/ai'
import { MdOutlineRateReview } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import AuthService from '../../services/auth.service'

const NavAdmin = () => {
    const currentUser = AuthService.getCurrentUser();

    return (
        <nav className='navAdmin'>
            <ul>
                {
                    // only Admin
                    currentUser.role === 1 ? (
                        <>
                            <li><NavLink className={({ isActive }) =>
                                isActive ? 'active' : undefined
                            } to="/admin/dashboard"><AiOutlinePieChart /> แดชบอร์ด</NavLink></li>
                            <li><NavLink to='/admin/chart'><AiOutlineLineChart /> กราฟ</NavLink></li>
                            <li><NavLink to='/admin/categories' ><AiOutlineApartment /> หมวดหมู่สินค้า</NavLink></li>
                            <li><NavLink to='/admin/products' ><AiOutlineShop /> สินค้า</NavLink></li>
                            <li><NavLink to='/admin/orders' ><AiOutlineSolution /> คำสั่งซื้อ</NavLink></li>
                            <li><NavLink to='/admin/users' ><AiOutlineUser /> ลูกค้า</NavLink></li>
                            <li><NavLink to='/admin/promotions'><AiOutlineFire /> โปรโมชั่น</NavLink></li>
                            <li><NavLink to='/admin/banner'><AiOutlineFileImage /> จัดการแบนเนอร์</NavLink></li>
                            <li><NavLink to='/admin/add-review'><MdOutlineRateReview /> รีวิว</NavLink></li>
                        </>
                    ) :  currentUser.role === 2 ? (
                        // Employee 
                        <>
                          <li><NavLink className={({ isActive }) =>
                                isActive ? 'active' : undefined
                            } to="/admin/dashboard"><AiOutlinePieChart /> แดชบอร์ด</NavLink></li>
                            <li><NavLink to='/admin/chart'><AiOutlineLineChart /> กราฟ</NavLink></li>
                            <li><NavLink to='/admin/orders' ><AiOutlineSolution /> คำสั่งซื้อ</NavLink></li>
                            <li><NavLink to='/admin/users' ><AiOutlineUser /> ลูกค้า</NavLink></li>
                            <li><NavLink to='/admin/promotions'><AiOutlineFire /> โปรโมชั่น</NavLink></li>
                            <li><NavLink to='/admin/banner'><AiOutlineFileImage /> จัดการแบนเนอร์</NavLink></li>
                            <li><NavLink to='/admin/add-review'><MdOutlineRateReview /> รีวิว</NavLink></li>
                        </>
                    ) : currentUser.role === 3 ? (
                        // PackingStaff
                        <li><NavLink to='/admin/orders' ><AiOutlineSolution /> คำสั่งซื้อ</NavLink></li>
                    ) : ''
                }
                
            </ul>
        </nav>
    )
}

export default NavAdmin