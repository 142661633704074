import "./loginAdmin.css";
import {  useState } from "react";
import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../../context/AuthContext";

import AuthService from "../../services/auth.service";

const LoginAdmin = () => {
  const [credentials, setCredentials] = useState({
    username: undefined,
    password: undefined,
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    AuthService.login(credentials).then(
      (res) => {
        navigate("/admin");
        window.location.reload();
      },
      (error) => {
        alert("เกิดข้อผิดพลาด!");
        console.log(error);
      }
    );
  };

  return (
    <div className="login-admin">
      <div className="container">
        <h1>เข้าสู่ระบบ</h1>
        <input
          type="text"
          name="username"
          placeholder="username"
          onChange={handleChange}
          style={{ boxSizing: 'border-box' }}
        />
        <input
          type="password"
          name="password"
          placeholder="password"
          onChange={handleChange}
          style={{ boxSizing: 'border-box' }}
        />
        <button onClick={handleClick}>Login</button>
      </div>
    </div>
  );
};

export default LoginAdmin;
