import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StepCheckOut from "../../components/stepCheckOut/StepCheckOut";
import "./checkout.css";
// redux
import { useDispatch, useSelector } from "react-redux";
import { changeValue } from "../../redux/paymentReducer";
import axios from "axios";

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customer = useSelector((state) => state.customer);
  const products = useSelector((state) => state.cart.products);
  const paymentName = useSelector((state) => state?.payment?.name);
  const paymentValue = useSelector((state) => state?.payment?.value);
  const [total, setTotal] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (paymentName === "" || products.length === 0) {
      navigate("/cart");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let items = 0;
    let total = 0;
    products.forEach((p) => {
      total += p.price * p.quantity;
      items += p.quantity;
    });
    setTotal(total);
    setTotalItems(items);
  }, [products]);

  useEffect(() => {
    if (paymentName === "เก็บเงินปลายทาง") {
      handleCod();
    } else if (paymentName === "EMS") {
      handleEms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalItems]);

  const handleCod = () => {
    if (totalItems <= 3) {
      dispatch(
        changeValue({
          name: "เก็บเงินปลายทาง",
          value: 50,
        })
      );
    } else if (totalItems <= 6) {
      dispatch(
        changeValue({
          name: "เก็บเงินปลายทาง",
          value: 70,
        })
      );
    } else if (totalItems <= 10) {
      dispatch(
        changeValue({
          name: "เก็บเงินปลายทาง",
          value: 90,
        })
      );
    } else {
      dispatch(
        changeValue({
          name: "เก็บเงินปลายทาง",
          value: 10 * totalItems,
        })
      );
    }
  };

  const handleEms = () => {
    if (totalItems <= 2) {
      dispatch(
        changeValue({
          name: "EMS",
          value: 50,
        })
      );
    } else {
      dispatch(
        changeValue({
          name: "EMS",
          value: 0,
        })
      );
    }
  };

  // value
  const [inputValue, setInputValue] = useState({
    fname: localStorage.getItem("customerFname"),
  });
  // const [dataProduct, setDataProduct] = useState({
  //   products: [],
  //   quantity: [],
  // })

  const handleChangeInput = (e) => {
    setInputValue((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleOrder = async () => {
    if (inputValue.fname === null) {
      return alert("กรุณากรอกข้อมูลให้ครับถ้วน!");
    }

    let items = [];
    let quantity = [];
    products.forEach((p) => {
      items.push(p.id);
      quantity.push(p.quantity);
    });

    try {
      // new user
      setLoading(true);

      await axios.post("/customer/", {
        userId: customer?.userId,
        pictureUrl: customer?.pictureUrl,
        displayName: customer?.displayName,
        name: inputValue.fname,
      });

      // new order =
      const resOrder = await axios.post("/order", {
        userId: customer.userId,
        total: total,
        status: 1,
        productId: items,
        quantity: quantity,
        payment: paymentName,
      });

      navigate("/complete-order/" + resOrder.data?.insertId);
    } catch (err) {
      alert("เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <div className="checkout">
      <p className="u-title">ข้อมูลผู้ใช้</p>
      <StepCheckOut step={2} />

      <div className="d-flex al-start md-f-column mt-2 gap-25px">
        <div className="account">
          <p className="top-title">
            <b>กรอกข้อมูลผู้ใช้</b>
          </p>
          <div className="d-grid g-2 gap-1rem md-g-1 md-gap-0">
            <div className="d-flex f-column gap-small mt-1">
              <label htmlFor="fname">
                ชื่อจริง <span>*</span>
              </label>
              <input
                type="text"
                className="input w-100"
                onChange={(e) => {
                  handleChangeInput(e);
                  localStorage.setItem("customerFname", e.target.value);
                }}
                defaultValue={localStorage.getItem("customerFname")}
                id="fname"
                placeholder="กรุณากรอกชื่อจริง"
              />
            </div>
            {/* <div className='d-flex f-column gap-small mt-1' >
              <label htmlFor="lname">นามสกุล <span>*</span></label>
              <input type="text" className='input w-100' onChange={(e) => {
                handleChangeInput(e);
                localStorage.setItem("customerLname", e.target.value);
              }} defaultValue={
                localStorage.getItem("customerLname")
              } id="lname" placeholder='กรุณากรอกนามสกุล' />
            </div> */}
          </div>
        </div>

        <div className="right">
          <p className="top-title">
            <b>รายการสินค้าของคุณ</b>
          </p>
          <div className="d-flex al-start jc-between second-title">
            <span>
              <b>สินค้า</b>
            </span>
            <span>
              <b>มูลค่า</b>
            </span>
          </div>

          {products.map((p) => (
            <div className="d-flex al-start jc-between" key={p.id}>
              <span>
                {p?.title} x {p?.quantity}
              </span>
              <span>
                <b>฿{formatNumber(p?.price * p?.quantity)}</b>
              </span>
            </div>
          ))}

          <div
            className="d-flex al-start jc-between"
            style={{
              borderTop: "1px dashed #EDEDED",
              margin: "1rem 0 0 0",
              padding: ".75rem 0 0 0 ",
            }}
          >
            <span>ค่าจัดส่ง</span>
            <span>
              <b>฿{paymentValue}</b>
            </span>
          </div>

          <div className="d-flex al-start jc-between total">
            <span>
              <b>ยอดรวม</b>
            </span>
            <span>
              <b>฿{formatNumber(total)}</b>
            </span>
          </div>

          {/* <Link to='/complete-order' > */}
          <button
            style={{ marginTop: "1rem", display: "block" }}
            disabled={loading ? true : false}
            onClick={handleOrder}
          >
            ส่งคำสั่งซื้อ
          </button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
