import "./listTableCart.css";
import { useEffect, useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import {  addMore, delOne } from "../../redux/cartReducer";
import { useDispatch } from "react-redux";

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};

const ListTableCart = ({ data }) => {
  const [screenSize, setScreenSize] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, []);

  return (
    <tr className="ListTableCart">
      <td>
        <div className="d-flex al-center jc-start gap-1rem">
          <div className="image">
            <img src={data?.img} alt="" />
            {screenSize > 767 ? "" : <p className="name">{data?.title}</p>}
          </div>
          {screenSize > 767 ? (
            <div>
              <p className="name">{data?.title}</p>
              <span className="price">฿{data?.price}</span>
            </div>
          ) : (
            ""
          )}
        </div>
      </td>
      <td>
        {screenSize > 767 ? "" : <span className="price">ราคา ฿{data?.price}</span>}
        <div>
          <div className="d-flex al-center jc-start quantity">
            <button
              className="d-flex al-center jc-center border-round minus"
              onClick={() => {
                dispatch(delOne(data?.id))
              }}
            >
              <AiOutlineMinus />
            </button>
            <span>{data?.quantity}</span>
            <button
              className="d-flex al-center jc-center border-round plus"
              onClick={() => {
                dispatch(addMore(data?.id))
              }}
            >
              <AiOutlinePlus />
            </button>
          </div>
        </div>
      </td>
      <td>
        <b  style={{color: '#fff'}}>฿{formatNumber(data?.quantity * data?.price)}</b>
      </td>
    </tr>
  );
};

export default ListTableCart;
