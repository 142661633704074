import liff from '@line/liff';
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { addCustomer } from "../../redux/customerReducer";
// import VConsole from 'vconsole';

const Liff = ({ home, flexContents }) => {
    // const vConsole = new VConsole();
    const dispatch = useDispatch();

    const initLine = () => {
        // liff.init({ liffId: process.env.REACT_APP_LINE_LIFF_ID }, () => {
        //     if (
        //         liff.getContext().type !== "none" &&
        //         liff.getContext().type !== "external"

        //     ) {
        //         if (liff.isLoggedIn()) {
        //             if (home) {
        //                 getProfile()
        //             } else {
        //                 sendMsg()
        //             }

        //         } else {
        //             liff.login();
        //         }
        //     } else {
        //         window.location.replace('https://line.me/ti/p/%40442kptio');
        //     }

        // }, err => console.error(err));
    }

    const getProfile = () => {
        liff.getProfile().then(profile => {
            dispatch(addCustomer({
                pictureUrl: profile.pictureUrl,
                displayName: profile.displayName,
                userId: profile.userId,
            }))

        }).catch(err => console.error(err));
    }

    const sendMsg = () => {
        liff
            .sendMessages([flexContents]).then(() => {
                console.log("message sent");
            }).then(() => {
                setTimeout(() => {
                  liff.closeWindow();
                }, 3000);
              })
            .catch((err) => {
                console.log("error", err);
            });
    }

    useEffect(() => {
        initLine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

}

export default Liff