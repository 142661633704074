import './updateOrderAdmin.css'
import { AiOutlineCopy, AiOutlineFileText, AiOutlinePlus, AiOutlineShoppingCart, AiOutlineUser } from 'react-icons/ai'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { useEffect, useState } from 'react'
import { IoCloseSharp } from 'react-icons/io5'
import AddProductOrder from '../../components/addProductOrder/AddProductOrder'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import Moment from 'react-moment'
import AuthService from '../../services/auth.service'


const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};


const UpdateOrderAdmin = () => {
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();

  // check role
  useEffect(()=>{
    if(currentUser?.role === 3 ){
      navigate("/admin/orders");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentUser?.role])

  const [popupStatus, setPopupStatus] = useState(false)
  const [textOptionStatus, setTextOptionStatus] = useState('เสร็จสิ้น')

  const [showAddProduct, setShowAddProduct] = useState(false);

  const { id } = useParams();

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  }

  const [data, setData] = useState('')
  const [products, setProducts] = useState([])
  const [customer, setCustomer] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const fetchDataCustomer = async (id) => {
    try {
      const res = await axios.get('/customer/u/' + id);
      setCustomer(res.data[0]);
    } catch (err) {
      console.log(err)
    }

  }

  const fetchApi = async () => {
    try {
      const res = await axios.get('/order/' + id)

      setValues(prev => ({
        ...prev,
        total: res.data[0].total,
        note: res.data[0].note
      }))

      switch (res.data[0]?.status) {
        case 1:
          setTextOptionStatus('กำลังดำเนินการ')
          break;
        case 2:
          setTextOptionStatus('เสร็จสิ้น')
          break;
        case 0:
          setTextOptionStatus('ยกเลิก')
          break;

        default:
          break;
      }
      if (res.data[0]?.userId) {
        fetchDataCustomer(res.data[0]?.userId);
      }

      setData(res.data[0])
      setProducts(res.data[0].products)

    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    counterItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  const handleUpdateOrder = async (status) => {
    try {
      await axios.put('/order/status/' + id, {
        status: status
      })
      alert('อัพเดทสถานะแล้ว')
      fetchApi()

    } catch (err) {
      alert('เกิดข้อผิดพลาด!')
      console.log(err)
    }
  }

  const [values, setValues] = useState({
    deleteProduct: [],
    productId: [],
    quantity: [],
    total: 0,
    note: ''
  })
  const counterItems = () => {
    let total = 0
    let price = 0
    products.forEach(d => {
      total += d?.quantity
      price += d?.quantity * d?.price
    });
    setTotalItems(total)
    setTotalPrice(price)
  }

  const handleRemoveProduct = (id) => {
    const newProducts = products.filter(p => (p.id !== id))
    setProducts(newProducts)
  }

  useEffect(() => {
    const qul = products.map(p => p.quantity)
    const product = products.map(p => p.id)

    setValues(prev => ({
      ...prev,
      deleteProduct: product,
      productId: product,
      quantity: qul,
      total: totalPrice
    }))
  }, [products, totalPrice])

  const update = async () => {
    try {
      await axios.put('/order/' + id, values);
      alert('อัพเดทเสร็จสิ้น')
      window.location.reload();

    } catch (err) {
      alert('เกิดข้อผิดพลาด!')
      console.log(err)
    }

  }

  return (
    <div className='update-order-admin'>
      <div className="d-flex jc-start al-center">
        <h2 className="heading">แก้ไขคำสั่งซื้อ</h2>
      </div>

      <div className='container'>
        <div className="d-flex al-start jc-between header">
          <div>
            <p><b>หมายเลขคำสั่งซื้อ #{id}</b></p>
            <p className='date'>วันที่ {<Moment format="DD-MM-YYYY เวลา HH:mm:ss">{customer?.createdAt}</Moment>} น.</p>
            {data?.status === 1 ? (
              <span className="status-pending">กำลังดำเนินการ</span>
            ) : data?.status === 2 ? (
              <span className='status-complete'>เสร็จสิ้น</span>
            ) : (
              <span className="status-cancel">ยกเลิก</span>
            )}
          </div>
          <div className='d-flex al-center jc-end actions'>
            <div className="d-flex al-center jc-end mr-1" style={{ position: 'relative' }}>
              <span>อัพเดทสถานะ</span>
              <div className='d-flex al-center jc-between option gap-small' onClick={() => { setPopupStatus(true) }}>{textOptionStatus}  <RiArrowDropDownLine /></div>
              <div className="dropdown" style={{ display: popupStatus ? 'block' : 'none' }} onClick={(e) => { setPopupStatus(false); }}>
                <ul onClick={(e) => { setTextOptionStatus(e.target.innerText) }}>
                  <li onClick={() => {
                    handleUpdateOrder(2)
                  }} className={textOptionStatus === 'เสร็จสิ้น' ? 'link active' : 'link'}>เสร็จสิ้น</li>
                  <li onClick={() => {
                    handleUpdateOrder(1)
                  }} className={textOptionStatus === 'กำลังดำเนินการ' ? 'link active' : 'link'}>กำลังดำเนินการ</li>
                  <li onClick={() => {
                    handleUpdateOrder(0)
                  }} className={textOptionStatus === 'ยกเลิก' ? 'link active' : 'link'}>ยกเลิก</li>
                </ul>
              </div>
            </div>
            <button className='d-flex al-center jc-center primary-button' onClick={update}>อัพเดท</button>
            <button className='d-flex al-center jc-center ml-small' onClick={() => {
              handleCopy('https://buriplus.com/complete-order/' + data?.id)
            }}><AiOutlineCopy /></button>
          </div>
        </div>

        {/* content */}
        <div className="d-grid g-2 gap-25px detail-content md-g-1">
          <div className="d-flex al-start jc-start md-f-column md-gap-small">
            <div className="icon d-flex al-center jc-center border-round">
              <AiOutlineUser />
            </div>
            <div>
              <p className="title"><b>ข้อมูลลูกค้า</b></p>
              <p>ชื่อ - นามสกุล : <b>{customer?.name}</b></p>
              <p>Line Name : <b>{customer?.displayName}</b></p>
              <p>uid : <b>{customer?.userId}</b></p>
            </div>
          </div>
          <div className="d-flex al-start jc-start md-f-column md-gap-small">
            <div className="icon d-flex al-center jc-center border-round">
              <AiOutlineShoppingCart />
            </div>
            <div>
              <p className="title"><b>รายละเอียคำสั่งซื้อ</b></p>
              <p>สถานะ : <b>{data?.status === 1 ? (
                'กำลังดำเนินการ'
              ) : data?.status === 2 ? (
                'เสร็จสิ้น'
              ) : (
                'ยกเลิก'
              )}</b></p>
              <p>แก้ไขล่าสุด : {<Moment format="DD-MM-YYYY เวลา HH:mm:ss">{customer?.updatedAt}</Moment>} น. </p>

              {data?.payment === 'เก็บเงินปลายทาง' ? (
              <span style={{ fontSize: '15px', marginTop: '1rem',display: 'inline-block', padding: '3px 10px', borderRadius: '30px', backgroundColor: '#5dbbd5', color: '#fff' }}>{data?.payment}</span>
            ) : (
              <span style={{ fontSize: '15px', marginTop: '1rem',display: 'inline-block', padding: '3px 10px', borderRadius: '30px', backgroundColor: '#d2d6d7', color: '#4a3e4a' }}>{data?.payment}</span>
            )}
            </div>
          </div>
          <div className="d-flex al-start jc-start md-f-column md-gap-small">
            <div className="icon d-flex al-center jc-center border-round">
              <AiOutlineFileText />
            </div>
            <div>
              <p className="title"><b>Note (สำหรับ Admin)</b></p>
              <textarea className="long-text" cols="30" rows="5" defaultValue={data?.note} onChange={(e) => {
                setValues(prev => ({
                  ...prev,
                  note: e.target.value
                }))
              }}></textarea>
              
            </div>
            <div>
              
            </div>
          </div>
        </div>
      </div>

      {/* table */}
      <div className="table">
        <div className="d-flex al-center jc-between pd-b-small">
          <p className="title">รายการสินค้า</p>
          <div style={{ padding: '0 1rem .5rem 1rem' }}>
            <button className='primary-button' onClick={() => { setShowAddProduct(true) }}><AiOutlinePlus /> เพิ่มสินค้า</button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>ชื่อสินค้า</th>
              <th>ราคาสินค้า</th>
              <th>จำนวนสินค้า</th>
              <th>ยอดรวม</th>
              <th>คำสั่ง</th>
            </tr>
          </thead>
          <tbody>
            {products && products.length > 0 ? (
              products.map(p => (
                <tr key={p.id}>
                  <td>
                    <div className='d-flex al-center jc-start'>
                      <div className="image">
                        <img src={process.env.REACT_APP_UPLOAD_URL + `/${p?.thumbnail}`} alt="" />
                      </div>
                      <span>{p?.name}</span>
                    </div>
                  </td>
                  <td>฿{formatNumber(p?.price)}</td>
                  <td>{p?.quantity}</td>
                  <td>฿{formatNumber(p?.price * p?.quantity)}</td>
                  <td>
                    <div className="d-flex al-center jc-center border-round icon" onClick={() => {
                      handleRemoveProduct(p?.id)
                    }}>
                      <IoCloseSharp />
                    </div>
                  </td>
                </tr>
              ))
            ) : <tr></tr>}
          </tbody>
          <tfoot>
            <tr>
              <td><b>ยอดรวม</b></td>
              <td></td>
              <td>{totalItems}</td>
              <td><b>฿{formatNumber(totalPrice)}</b></td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <AddProductOrder show={showAddProduct} setShow={setShowAddProduct} prevData={products} setPrevData={setProducts} defaultValue={values} setValue={setValues} />
      <div className={showAddProduct ? "overlay show" : "overlay"} onClick={() => { setShowAddProduct(false) }}></div>
    </div>
  )
}

export default UpdateOrderAdmin