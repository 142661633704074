import "./sidebarCart.css";
import { GrClose } from "react-icons/gr";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

// redux
import { useDispatch, useSelector } from "react-redux";
import { removeItem, addMore, delOne } from "../../redux/cartReducer";

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};

const SidebarCart = ({ show }) => {
  const products = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const getTotal = () => {
      let total = 0;
      products &&
        products.forEach((p) => {
          total += p.price * p.quantity;
        });
      setTotal(formatNumber(total));
    };
    getTotal();
  }, [products]);

  return (
    <div className={show ? "p-1rem sidebarCart show" : "p-1rem sidebarCart"}>
      <p className="u-title">ตะกร้าสินค้า</p>
      {products.length > 0 ? (
        <div className="lists">
          <ul>
            {products.map((p) => (
              <li className="d-flex al-start jc-start gap-25px" key={p?.id}>
                <div className="image">
                  <img src={p?.img} alt="" />
                </div>
                <div className="detail w-100">
                  <p className="name">{p?.title}</p>
                  <span className="amount">
                    {p?.quantity} x ฿{formatNumber(p?.price)}
                  </span>
                  <div className="quantity-mini-cart">
                    <button
                      className="d-flex al-center jc-center border-round minus"
                      onClick={() => dispatch(delOne(p?.id))}
                    >
                      <AiOutlineMinus />
                    </button>
                    <span>{p?.quantity}</span>
                    <button
                      className="d-flex al-center jc-center border-round plus"
                      onClick={() => dispatch(addMore(p?.id))}
                    >
                      <AiOutlinePlus />
                    </button>
                  </div>
                  <GrClose onClick={() => dispatch(removeItem(p?.id))} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p style={{ padding: "1rem 0" }}>ยังไม่มีสินค้าในตะกร้า</p>
      )}

      <div className="d-flex al-center jc-between total">
        <span>
          <b>ยอดรวม</b>
        </span>
        <span>
          <b>฿{total}</b>
        </span>
      </div>

      <div className="d-flex f-column buttons">
        <Link to="/cart">
          <button className="add-to-cart">ดูสินค้าในตะกร้า</button>
        </Link>
        {/* <Link to="/checkout">
          <button className="add-to-cart">ยืนยันออเดอร์</button>
        </Link> */}
      </div>
    </div>
  );
};

export default SidebarCart;
