import './stepCheckOut.css'
import { BsBagCheck, BsCheck, BsPerson, BsFileEarmarkMedical } from 'react-icons/bs'

const StepCheckOut = ({ step }) => {
    return (
        <div className='d-flex stepCheckout'>
            <div className='step active'>
                <BsBagCheck />
                <div className="circle">
                    <BsCheck />
                </div>
                <p>ตะกร้าสินค้า</p>
            </div>
            <div className={step >= 2 ? 'step active' : 'step'}>
                <BsPerson />
                <div className="circle">
                    {step >= 2 ? <BsCheck /> : 2}
                </div>
                <p>ข้อมูลผู้ใช้</p>
            </div>
            <div className={step >= 3 ? 'step active' : 'step'}>
                <BsFileEarmarkMedical />
                <div className="circle">
                    {step >= 3 ? <BsCheck /> : 3}
                </div>
                <p>ยืนยันออเดอร์</p>
            </div>
            <div className='progress active' style={{ width: step === 1 ? '0' : step === 2 ? 'calc(50% - 30px)' : 'calc(100% - 60px)' }}></div>
            <div className='progress'></div>
        </div>
    )
}

export default StepCheckOut