import "./updateDealOfDay.css";
import { DatePicker, Space } from "antd";
import "dayjs/locale/th";
import locale from "antd/es/date-picker/locale/th_TH";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";
import AuthService from "../../services/auth.service";

const UpdateDealOfDay = () => {
  const { id } = useParams();

  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();

  // check role
  useEffect(()=>{
    if(currentUser?.role === 3 ){
      navigate("/admin/orders");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentUser?.role])

  const dateFormatList = ["DD-MM-YYYY", "DD-MM-YY"];

  const handlePickerDateStart = (date, dateString) => {
    setValues((prev) => ({ ...prev, dayStart: date }));
  };
  const handlePickerDateEnd = (date, dateString) => {
    setValues((prev) => ({ ...prev, dayEnd: date }));
  };

  const [values, setValues] = useState({
    dayStart: "",
    dayEnd: "",
    promotion_price: '',
    promotion_onsale: '',
  });

  const [product, setProduct] = useState([]);
  useEffect(() => {
    const fetchProduct = async (id) => {
      try {
        const res = await axios.get("/deal/" + id);
        setProduct(res.data[0]);

        res.data[0]?.dayStart !== null ? setValues(prev => ({ ...prev, dayStart: res.data[0]?.dayStart })) : setValues(prev => ({ ...prev, dayStart: '' }))
        res.data[0]?.dayEnd !== null ? setValues(prev => ({ ...prev, dayEnd: res.data[0]?.dayEnd })) : setValues(prev => ({ ...prev, dayEnd: '' }))
        res.data[0]?.promotion_onsale !== null ? setValues(prev => ({ ...prev, promotion_onsale: res.data[0]?.promotion_onsale })) : setValues(prev => ({ ...prev, promotion_onsale: '' }))
        res.data[0]?.promotion_price !== null ? setValues(prev => ({ ...prev, promotion_price: res.data[0]?.promotion_price })) : setValues(prev => ({ ...prev, promotion_price: '' }))

      } catch (err) {
        console.log(err);
      }
    };
    fetchProduct(id);
  }, [id]);

  const handleSubmit = async (id) => {
    try {
      await axios.put("/deal/" + id, values);
      alert("แก้ไขโปรโมชั่นสำเร็จ");
      navigate("/admin/promotions");
    } catch (err) {
      alert("เกิดข้อผิดพลาด!");
      console.log(err);
    }
  };

  return (
    <div className="UpdateDealOfDay">
      <div className="d-flex jc-between al-center margin-bt-1">
        <h2 className="heading">แก้ไขโปรโมชั่น</h2>
        <div className="d-flex al-center jc-end gap-small">
          <button
            className="primary-button"
            onClick={() => {
              handleSubmit(id);
            }}
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="container">
        {/* left */}
        <div className="d-grid g-2 md-g-1 gap-25px">
          <div>
            <div className="d-grid g-2 gap-1rem md-g-1 mb-2">
              <div>
                <p className="title">
                  วันเริ่มต้น<span>*</span>
                </p>
                <div className="input" style={{ height: "36px" }}>
                  <Space direction="vertical">
                    <DatePicker
                      locale={locale}
                      style={{ display: "block", padding: "0", height: "40px" }}
                      format={dateFormatList}
                      onChange={handlePickerDateStart}
                      bordered={false}
                      clearIcon={true}
                      suffixIcon={null}
                      placeholder={
                        moment(product?.newDateStart, dateFormatList[0])._i
                      }
                      className="datepick"
                    />
                  </Space>
                </div>
              </div>
              <div>
                <p className="title">
                  วันสิ้นสุด<span>*</span>
                </p>
                <div className="input" style={{ height: "36px" }}>
                  <Space direction="vertical">
                    <DatePicker
                      locale={locale}
                      style={{ display: "block", padding: "0", height: "40px" }}
                      format={dateFormatList}
                      onChange={handlePickerDateEnd}
                      bordered={false}
                      clearIcon={true}
                      suffixIcon={null}
                      placeholder={
                        moment(product?.newDateEnd, dateFormatList[0])._i
                      }
                      className="datepick"
                    />
                  </Space>
                </div>
              </div>
            </div>
            <p className="title">ชื่อสินค้า</p>
            <span>{product?.name}</span>
            <div className="d-grid g-2 gap-1rem md-g-1 mt-2">
              <div>
                <p className="title">
                  ราคาสินค้า<span>*</span>
                </p>
                <input
                  type="number"
                  defaultValue={
                    product.promotion_price !== null
                      ? product.promotion_price
                      : ""
                  }
                  className="md-mb-0"
                  onChange={(e) => {
                    setValues((prev) => ({
                      ...prev,
                      promotion_price: e.target.value,
                    }));
                  }}
                  placeholder="กรอกราคาสินค้า"
                />
              </div>
              <div>
                <p className="title">
                  ลดเหลือ<span>*</span>
                </p>
                <input
                  type="number"
                  defaultValue={
                    product.promotion_onsale !== null
                      ? product.promotion_onsale
                      : ""
                  }
                  onChange={(e) => {
                    setValues((prev) => ({
                      ...prev,
                      promotion_onsale: e.target.value,
                    }));
                  }}
                  placeholder="กรอกราคาสินค้า"
                />
              </div>
            </div>
            <p className="title">รายละเอียดสินค้า</p>
            <span>{product?.description}</span>
            <p className="title mt-2">หมวดหมู่สินค้า</p>

            <div className="d-flex al-center jc-end">
              <div
                className="d-flex al-center jc-between option ml-0"
                style={{ cursor: "no-drop" }}
              >
                {product?.cateName !== undefined
                  ? product?.cateName
                  : "ไม่มีหมวดหมู่"}
              </div>
            </div>
          </div>

          {/* right */}
          <div>
            <div>
              <p className="title">ภาพสินค้า</p>
              <div
                className="d-flex al-center jc-center thumbnail"
                style={{ cursor: "auto" }}
              >
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL + `/${product?.thumbnail}`
                  }
                  alt=""
                />
              </div>
            </div>

            {/* gallery */}
            <div className="d-grid g-4 md-g-2 gap-1rem old-gallery">
              {product?.gallery && product?.gallery.length > 0
                ? product?.gallery.map((g) => (
                  <div
                    key={g.id}
                    className="d-flex al-center jc-center old-list"
                  >
                    <div className="d-flex al-center jc-center image">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL + `/${g?.imgUrl}`
                        }
                        alt=""
                      />
                    </div>
                  </div>
                ))
                : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateDealOfDay;
