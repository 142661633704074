
const HowToOrder = () => {
    const style = {
        textAlign: 'center',
        img:{
            width: '100%',
            maxWidth: '55vw',
            marginBottom: '.5rem'
        }
    }

    return (
        <div>
            <p className="u-title">ขั้นตอนการสั่งซื้อ</p>
            <div className="d-grid g-3 md-g-1 w-100" style={{width: '100%',maxWidth: '700px', margin: '3rem auto', gap: '4rem'}}>
                <div style={style}>
                    <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/h-1.png'} style={style.img} alt="" />
                    <p>เลือกสินค้าที่ต้องการ</p>
                </div>
                <div style={style}>
                    <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/h-2.png'} style={style.img} alt="" />
                    <p>หยิบสินค้าใส่ตะกร้า</p>
                </div>
                <div style={style}>
                    <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/h-3.png'} style={style.img} alt="" />
                    <p>กรอกข้อมูลผู้ใช้และส่งคำสั่งซื้อ</p>
                </div>
                <div style={style}>
                    <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/h-4.png'} style={style.img} alt="" />
                    <p>รอเจ้าหน้าที่ยืนยันคำสั่งซื้อ</p>
                </div>
                <div style={style}>
                    <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/h-5.png'} style={style.img} alt="" />
                    <p>ชำระเงิน</p>
                </div>
                <div style={style}>
                    <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/h-6.png'} style={style.img} alt="" />
                    <p>รอรับสินค้า</p>
                </div>
            </div>
        </div>
    )
}

export default HowToOrder