import SidebarCategoryMobile from '../sidebarCategoryMobile/SidebarCategoryMobile'
import './sidebarMenuMobile.css'

const SidebarMenuMobile = ({ show }) => {
    return (
        <div className={show ? 'sidebarMenuMobile show' : 'sidebarMenuMobile'}>
            <div className='sidebar-container'>
            {/* <p className="u-title">เมนู</p>
            <ul className='list'>
                <li><NavLink className={({ isActive }) =>
                    isActive ? 'list active' : 'list'
                } to="/">หน้าแรก</NavLink></li>
                <li><NavLink className="list" to="/products">สินค้า</NavLink></li>
                <li><NavLink className="list" to="/promotions">โปรโมชั่นของเรา</NavLink></li>
                <li><NavLink className="list" to="/how-to-order">วิธีการสั่งซื้อ</NavLink></li>
                <li><NavLink className="list" to="/payment">การชำะเงิน</NavLink></li>
                <li><NavLink className="list" to="/review">รีวิวจากลูกค้า</NavLink></li>
                <li><NavLink className="list" to="/contact-us">ติดต่อเรา</NavLink></li>
            </ul> */}

            <SidebarCategoryMobile />
            </div>
        </div>
    )
}

export default SidebarMenuMobile