
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto'; // ADD THIS



const ChartStatusOrders = ({ dataValues }) => {

  const data = {
    labels: ['สำเร็จ', 'กำลังดำเนินการ', 'ยกเลิก'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [dataValues[5][0].status0, dataValues[6][0].status1, dataValues[7][0].status2],
        backgroundColor: ['#4bc0c0', '#ff9f40', '#ff6384'],
      }
    ]
  };

  return (
    <div>
      <Pie options={{
        responsive: true,
        aspectRatio: false
      }} width={550} height={350} data={data} />
    </div>
  )

}

export default ChartStatusOrders