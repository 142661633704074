import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./flashDeal.css";

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};

const FlashDeal = ({ page }) => {
  // products
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/product/flashSale");
        setProducts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="flashDeal d-grid g-2 gap-1rem mt-2 md-g-1">
      {products && products.length > 0
        ? products.map((p, index) =>
            index < 2 && page && page === "home" ? (
              <div className="d-grid g-2" key={p.id}>
                <div className="thumbnail">
                  <div className="onSale">฿{formatNumber(p?.discount)}</div>
                  <img
                    src={process.env.REACT_APP_UPLOAD_URL + `/${p?.thumbnail}`}
                    alt=""
                  />
                </div>
                <div className="d-flex f-column al-start jc-center p-2rem">
                  <p className="tag-tile">เป็นกระแส</p>
                  <p className="name">{p?.name}</p>
                  <Link to={"/product/" + p?.id}>
                    <button className="add-to-cart">ร้านค้า</button>
                  </Link>
                </div>
              </div>
            ) : (
              <div className="d-grid g-2" key={p.id}>
                <div className="thumbnail">
                  <div className="onSale">฿{formatNumber(p?.discount)}</div>
                  <img
                    src={process.env.REACT_APP_UPLOAD_URL + `/${p?.thumbnail}`}
                    alt=""
                  />
                </div>
                <div className="d-flex f-column al-start jc-center p-2rem">
                  <p className="tag-tile">Trending</p>
                  <p className="name">{p?.name}</p>
                  <Link to={"/product/" + p?.id}>
                    <button className="add-to-cart">SHOP NOW</button>
                  </Link>
                </div>
              </div>
            )
          )
        : ""}
    </div>
  );
};

export default FlashDeal;
