import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import { LayoutAdmin } from "./layouts/layoutAdmin/LayoutAdmin";

// admin router
import DashboardAdmin from "./pages/dashboardAdmin/DashboardAdmin";
import ChartAdmin from "./pages/chartAdmin/ChartAdmin";
import ProductsAdmin from "./pages/productsAdmin/ProductsAdmin";
import PromotionsAdmin from "./pages/promotionsAdmin/PromotionsAdmin";
import AddProduct from "./pages/addProduct/AddProduct";
import UpdateProduct from "./pages/updateProduct/UpdateProduct";
import Categories from "./pages/categories/Categories";
import OrderAdmin from "./pages/orderAdmin/OrderAdmin";
import UpdateOrderAdmin from "./pages/updateOrderAdmin/UpdateOrderAdmin";
import OrderDetailAdmin from "./pages/orderDetailAdmin/OrderDetailAdmin";
import Users from "./pages/users/Users";
import UserDetailAdmin from "./pages/userDetailAdmin/UserDetailAdmin";
import UpdateUser from "./pages/updateUser/UpdateUser";
import UpdateDealOfDay from "./pages/updateDealOfDay/UpdateDealOfDay";

// public
import Home from "./pages/home/Home";
import LayoutPublic from "./layouts/layoutPublic/LayoutPublic";
import Products from "./pages/products/Products";
import Category from "./pages/category/Category";
import Product from "./pages/product/Product";
import Promotions from "./pages/promotions/Promotions";
import Cart from "./pages/cart/Cart";
import Checkout from "./pages/checkout/Checkout";
import CompleteOrder from "./pages/completeOrder/CompleteOrder";
import ConfirmOrder from "./pages/confirmOrder/ConfirmOrder";
import HowToOrder from "./pages/howToOrder/HowToOrder";
import Payment from "./pages/payment/Payment";
import Review from "./pages/review/Review";
import Contact from "./pages/contact/Contact";
import LoginAdmin from "./pages/loginAdmin/LoginAdmin";
import AuthService from "./services/auth.service";
import axios from "axios";
import BannerAdmin from "./pages/bannerAdmin/BannerAdmin";
import AddReview from "./pages/addReview/AddReview";
function App() {
  const currentUser = AuthService.getCurrentUser();

  const ProtectRoutes = ({ children }) => {
    // const { user } = useContext(AuthContext);
    
    axios.defaults.headers.common['Authorization'] = currentUser?.accessToken;
    if (currentUser?.role === undefined && currentUser?.role !== 1 ) {
      return <Navigate to="/" />;
    }

    return children;
  };

  const AdminLayout = () => {
    return (
      <>
        <ProtectRoutes>
          <LayoutAdmin>
            <Outlet />
          </LayoutAdmin>
        </ProtectRoutes>
      </>
    );
  };

  
  const PublicLayout = () => {
    return (
      <>
        <LayoutPublic>
          <Outlet />
        </LayoutPublic>
      </>
    );
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/admin-login',
      element: <LoginAdmin />,
    },
    {
      path: '/',
      element: <PublicLayout />,
      children: [
        {
          path: 'category/:id',
          element: <Category />,
        },
        {
          path: 'products',
          element: <Products />,
        },
        {
          path: 'product/:id',
          element: <Product />,
        },
        {
          path: 'promotions',
          element: <Promotions />,
        },
        {
          path: 'cart',
          element: <Cart />,
        },
        {
          path: 'checkout',
          element: <Checkout />,
        },
        {
          path: 'complete-order/:id',
          element: <CompleteOrder />,
        },
        {
          path: 'confirm-order',
          element: <ConfirmOrder />,
        },
        {
          path: 'how-to-order',
          element: <HowToOrder />,
        },
        {
          path: 'payment',
          element: <Payment />,
        },
        {
          path: 'review',
          element: <Review />,
        },
        {
          path: 'contact-us',
          element: <Contact />,
        },
      ],
    },
    {
      path: '/admin/',
      element: <AdminLayout />,
      children: [
        {
          path: '',
          element: <DashboardAdmin />,
        },
        {
          path: 'dashboard',
          element: <DashboardAdmin />,
        },
        {
          path: 'chart',
          element: <ChartAdmin />,
        },
        {
          path: 'products',
          element: <ProductsAdmin />,
        },
        {
          path: 'new-product',
          element: <AddProduct />,
        },
        {
          path: 'update-product/:id',
          element: <UpdateProduct />,
        },
        {
          path: 'categories',
          element: <Categories />,
        },
        {
          path: 'orders',
          element: <OrderAdmin />,
        },
        {
          path: 'order/:id',
          element: <OrderDetailAdmin />,
        },
        {
          path: 'update-order/:id',
          element: <UpdateOrderAdmin />,
        },
        {
          path: 'users',
          element: <Users />,
        },
        {
          path: 'user/:id',
          element: <UserDetailAdmin />,
        },
        {
          path: 'update-user/:id',
          element: <UpdateUser />,
        },
        {
          path: 'promotions',
          element: <PromotionsAdmin />,
        },
        {
          path: 'update-deal/:id',
          element: <UpdateDealOfDay />,
        },
        {
          path: 'banner',
          element: <BannerAdmin />,
        },
        {
          path: 'add-review',
          element: <AddReview />,
        },
      ]
    },


  ]);

  return (
    <RouterProvider router={router} />
  )
}

export default App;
