import { Line } from "react-chartjs-2";
import "chart.js/auto"; // ADD THIS
import { useEffect, useState } from "react";
import axios from "axios";

const SalesAnalytics = () => {
  const [dataAnl, setDataAnl] = useState([]);
  useEffect(() => {
    const fetchApi = async () => {
      try {
        const res = await axios.get("/analytics/chart");
        setDataAnl(res.data)
      } catch (err) {
        console.log(err)
      }
    };
    fetchApi();
  }, []);

  const data = {
    labels: [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ],
    datasets: [
      // {
      //     label: 'จำนวนสินค้า',
      //     data: [33, 53, 85, 41, 44, 65],
      //     fill: true,
      //     backgroundColor: 'rgba(75,192,192,0.2)',
      //     borderColor: 'rgba(75,192,192,1)'
      // },
      {
        label: "ยอดขาย",
        data: dataAnl,
        fill: false,
        borderColor: "#742774",
      },
    ],
  };

  return (
    <Line
      options={{
        responsive: true,
        aspectRatio: false,
      }}
      width={550}
      height={350}
      data={data}
    />
  );
};

export default SalesAnalytics;
