import "./updateProduct.css";
import { useEffect, useRef, useState } from "react";
import { BsCheck } from "react-icons/bs";
import { RiArrowDropDownLine, RiImageAddFill } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import AuthService from "../../services/auth.service";

const UpdateProduct = () => {
  const { id } = useParams();
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();
  // check role
  useEffect(() => {
    if (currentUser?.role !== 1) {
      navigate("/admin/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.role])

  const [popupCate, setPopupCate] = useState(false);
  const [textOptionCate, setTextOptionCate] = useState("เลือกหมวดหมู่");

  const discount = useRef(null)

  // CATEGORY
  const [category, setCategory] = useState([]);
  useEffect(() => {
    const fetchCate = async () => {
      const res = await axios.get("/category/all");
      setCategory(res.data);
    };
    fetchCate();
  }, []);

  // changeCate
  const [changeCate, setChangeCate] = useState(false);
  const [oldCateName, setOldCateName] = useState("");

  useEffect(() => {
    const oldCate = category.find((c) => c.id === 5);
    setOldCateName(oldCate?.name);
  }, [category]);

  const [values, setValues] = useState({
    name: "",
    price: "",
    discount: "",
    description: "",
    cateId: "",
    thumbnail: "",
    status: "",
    gallery: [],
    deleteGalleryId: [],
    newThumbnail: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/product/admin/" + id);

        setValues((prev) => ({
          ...prev,
          name: res.data[0]?.name,
          price: res.data[0]?.price,
          discount: res.data[0]?.discount,
          description: res.data[0]?.description,
          cateId: res.data[0]?.cateId,
          thumbnail: res.data[0]?.thumbnail,
          status: res.data[0]?.status,
          gallery: res.data[0]?.gallery,
        }));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  const handleChangeValues = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // thumbnail
  const [thumbnail, setThumbnail] = useState(null);
  const thumbnailChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setThumbnail(e.target.files[0]);
    }
  };

  // gallery
  const [gallery, setGallery] = useState([]);
  const [previewGallery, setPreviewGallery] = useState([]);
  const handleChange = (event) => {
    let newFiles = [...gallery, ...event.target.files];
    setGallery(newFiles);
    let preview = [];
    for (let i = 0; i < newFiles.length; i++) {
      let file = newFiles[i];
      let reader = new FileReader();
      reader.onloadend = () => {
        preview.push(reader.result);
        setPreviewGallery(preview);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = (index) => {
    let galleryCopy = [...previewGallery];
    galleryCopy.splice(index, 1);
    setPreviewGallery(galleryCopy);

    let fileGallery = [...gallery];
    fileGallery.splice(index, 1);
    setGallery(fileGallery);
  };

  // old gallery
  const handleDelOldGallery = (id) => {
    // gallery
    let dataGallery = [...values?.gallery];
    dataGallery = dataGallery.filter((img) => img.id !== id);

    // delete gallery
    let delImg = [...values?.deleteGalleryId];
    delImg.push(id);

    setValues((prev) => ({
      ...prev,
      gallery: dataGallery,
      deleteGalleryId: delImg,
    }));
  };

  const handleUpload = (status) => {
    let formData = new FormData();

    formData.append("name", values.name);
    formData.append("price", values.price);

    if (discount.current.value === '') {
      formData.append("discount", '');
    } else {
      formData.append("discount", values.discount);
    }

    formData.append("description", values.description);
    formData.append("cateId", values.cateId);

    formData.append("thumbnail", thumbnail);
    for (let i = 0; i < gallery.length; i++) {
      formData.append("gallery", gallery[i]);
    }

    for (let i = 0; i < values.deleteGalleryId.length; i++) {
      formData.append("deleteGalleryId", values.deleteGalleryId[i]);
    }

    formData.append("newThumbnail", values.newThumbnail);

    formData.append("status", status);

    axios
      .put(`/product/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        alert("แก้ไขสินค้าสำเร็จ");
        navigate("/admin/products");
      })
      .catch((err) => {
        alert("เกิดข้อผิดพลาด!");
        console.error(err);
      });
  };

  return (
    <div className="update-product">
      <div className="d-flex jc-between al-center margin-bt-1">
        <h2 className="heading">สินค้า</h2>
        <div className="d-flex al-center jc-end gap-small">
          <button
            className="second-button"
            onClick={() => {
              handleUpload(1);
            }}
          >
            <BsCheck /> เผยแพร่
          </button>
          <button
            className="primary-button"
            onClick={() => {
              handleUpload(0);
            }}
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="container">
        {/* left */}
        <div className="d-grid g-2 md-g-1 gap-25px">
          <div>
            <p className="title">
              ชื่อสินค้า<span>*</span>
            </p>
            <input
              type="text"
              defaultValue={values.name}
              onChange={handleChangeValues}
              name="name"
              placeholder="กรอกชื่อสินค้า"
            />
            <div className="d-grid g-2 gap-1rem md-g-1">
              <div>
                <p className="title">
                  ราคาสินค้า<span>*</span>
                </p>
                <input
                  type="number"
                  defaultValue={values.price}
                  onChange={handleChangeValues}
                  name="price"
                  placeholder="กรอกราคาสินค้า"
                />
              </div>
              <div>
                <p className="title">ลดเหลือ</p>

                <input
                  type="number"
                  defaultValue={values.discount}
                  onChange={handleChangeValues}
                  name="discount"
                  placeholder="กรอกราคาสินค้า"
                  ref={discount}
                />
              </div>
            </div>
            <p className="title">รายละเอียดสินค้า</p>
            <textarea
              placeholder="กรอกรายละเอียดสินค้า"
              defaultValue={values.description}
              onChange={handleChangeValues}
              name="description"
            ></textarea>

            <p className="title">หมวดหมู่สินค้า</p>

            <div
              className="d-flex al-center jc-end"
              style={{ position: "relative" }}
            >
              <div
                className="d-flex al-center jc-between option ml-0"
                onClick={() => {
                  setPopupCate(true);
                }}
              >
                {" "}
                {changeCate === false
                  ? values?.cateId === 0
                    ? "เลือกหมวดหมู่"
                    : oldCateName
                  : textOptionCate}{" "}
                <RiArrowDropDownLine />
              </div>
              <div
                className="dropdown"
                style={{ display: popupCate ? "block" : "none", width: "100%" }}
                onClick={(e) => {
                  setPopupCate(false);
                }}
              >
                <ul
                  onClick={(e) => {
                    setTextOptionCate(e.target.innerText);
                    setChangeCate(true);
                  }}
                >
                  <li
                    value={0}
                    onClick={() => {
                      setValues((prev) => ({ ...prev, cateId: 0 }));
                    }}
                    className={
                      textOptionCate === "เลือกหมวดหมู่"
                        ? "link active"
                        : "link"
                    }
                  >
                    เลือกหมวดหมู่
                  </li>
                  {category.length > 0
                    ? category.map((c) => (
                      <li
                        key={c.id}
                        value={c.id}
                        onClick={() => {
                          setValues((prev) => ({ ...prev, cateId: c.id }));
                        }}
                        className={
                          textOptionCate === c?.name ? "link active" : "link"
                        }
                      >
                        {c?.name}
                      </li>
                    ))
                    : ""}
                </ul>
              </div>
            </div>
          </div>

          {/* right */}
          <div>
            <div>
              <p className="title">
                ภาพสินค้า<span>*</span>
              </p>
              <label
                htmlFor="thumbnail"
                className="d-flex al-center jc-center thumbnail"
              >
                <input
                  accept="image/*"
                  type="file"
                  onChange={(e) => {
                    setValues((prev) => ({
                      ...prev,
                      newThumbnail: 1,
                    }));
                    thumbnailChange(e);
                  }}
                  id="thumbnail"
                  style={{ display: "none" }}
                />
                {values?.thumbnail === undefined || values?.thumbnail === "" ? (
                  <div className="d-flex f-column al-center jc-center">
                    <RiImageAddFill />
                    <small>คลิ๊กเพื่อเพิ่มภาพสินค้า</small>
                  </div>
                ) : thumbnail !== null ? (
                  <img
                    src={URL.createObjectURL(thumbnail)}
                    alt="Thumb"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={
                      process.env.REACT_APP_UPLOAD_URL + `/${values?.thumbnail}`
                    }
                    alt="Thumb"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </label>
            </div>
            <div>
              <p className="title">แกลเลอรี่สินค้า</p>
              <label
                htmlFor="gallery"
                className="d-flex al-center jc-center galleries"
              >
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleChange}
                  id="gallery"
                  style={{ display: "none" }}
                  multiple
                />
                <div className="d-flex f-column al-center jc-center">
                  <RiImageAddFill />
                  <small>คลิ๊กเพื่อเพิ่มภาพสินค้า</small>
                </div>
              </label>
            </div>

            {/* old gallery */}
            <div className="d-grid g-4 md-g-2 gap-1rem old-gallery">
              {values?.gallery.length > 0
                ? values?.gallery.map((img) => (
                  <div
                    key={img.id}
                    className="d-flex al-center jc-center old-list"
                  >
                    <div
                      className="action"
                      onClick={() => {
                        handleDelOldGallery(img.id);
                      }}
                    >
                      <div className="d-flex al-center jc-center border-round icon">
                        <IoCloseSharp />
                      </div>
                    </div>
                    <div className="d-flex al-center jc-center image">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL + `/${img?.imgUrl}`
                        }
                        alt=""
                      />
                    </div>
                  </div>
                ))
                : ""}

              {/* <div className='d-flex al-center jc-center old-list'>
                                <div className="action">
                                    <div className="d-flex al-center jc-center border-round icon">
                                        <IoCloseSharp />
                                    </div>
                                </div>
                                <div className='d-flex al-center jc-center image'>
                                    <img src="https://images.pexels.com/photos/10526915/pexels-photo-10526915.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                                </div>
                            </div>
                            <div className='d-flex al-center jc-center old-list'>
                                <div className="action">
                                    <div className="d-flex al-center jc-center border-round icon">
                                        <IoCloseSharp />
                                    </div>
                                </div>
                                <div className='d-flex al-center jc-center image'>
                                    <img src="https://images.pexels.com/photos/10526915/pexels-photo-10526915.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                                </div>
                            </div>
                            <div className='d-flex al-center jc-center old-list'>
                                <div className="action">
                                    <div className="d-flex al-center jc-center border-round icon">
                                        <IoCloseSharp />
                                    </div>
                                </div>
                                <div className='d-flex al-center jc-center image'>
                                    <img src="https://images.pexels.com/photos/10526915/pexels-photo-10526915.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                                </div>
                            </div>
                            <div className='d-flex al-center jc-center old-list'>
                                <div className="action">
                                    <div className="d-flex al-center jc-center border-round icon">
                                        <IoCloseSharp />
                                    </div>
                                </div>
                                <div className='d-flex al-center jc-center image'>
                                    <img src="https://images.pexels.com/photos/10526915/pexels-photo-10526915.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                                </div>
                            </div> */}
            </div>

            {/* new upload */}
            <div className="upload-galleries">
              {previewGallery.map((image, index) => (
                <div
                  key={index}
                  className="list d-flex al-center jc-between gap-1rem"
                >
                  <div className="d-flex al-center jc-start gap-1rem">
                    <img src={image} alt="" />
                    <span>{gallery[index].name}</span>
                  </div>
                  <div
                    className="d-flex al-center jc-center border-round icon"
                    onClick={() => handleDelete(index)}
                  >
                    <IoCloseSharp />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProduct;
