import './layoutPublic.css'
import { useEffect, useState } from 'react'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import MiniCart from '../../components/miniCart/MiniCart'
import MobileNav from '../../components/mobileNav/MobileNav'
import Nav from '../../components/nav/Nav'
import Sidebar from '../../components/sidebar/Sidebar'
import ScrollToTop from '../../ScrollToTop'

const LayoutPublic = ({ children }) => {

  const [screenSize, setScreenSize] = useState(0)

  useEffect(() => {
    setScreenSize(window.innerWidth)
  }, [])

  return (
    <>
      <Header />
      <Nav />
      <main className='user' style={{ marginTop: '1.2rem' }}>
        <ScrollToTop />
        {screenSize > 767 ? <Sidebar /> : <MobileNav />}
        {children}
      </main>
      <Footer />
      <MiniCart />
      {/* {screenSize > 767 ? <MiniCart /> : ''} */}
    </>
  )
}

export default LayoutPublic