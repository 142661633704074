import { useEffect, useState } from "react";
import CardHighlight from "../../components/cardHighlight/CardHighlight";
import SalesAnalytics from "../../components/salesAnalytics/SalesAnalytics";
import ChartStatusOrders from "../../components/chartStatusOrders/ChartStatusOrders";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import FilterChart from "../../components/filterChart/FilterChart";


const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};



const ChartAdmin = () => {
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();

  // check role
  useEffect(() => {
    if (currentUser?.role === 3) {
      navigate("/admin/orders");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.role]);

  const [timeStart, setTimeStart] = useState("");
  const [timeEnd, setTimeEnd] = useState("");
  const [payment, setPayment] = useState("");

  const [data, setData] = useState([]);

  useEffect(() => {
    const fethData = async () => {
      let url = "/analytics/all";
      url += `?timeStart=${timeStart}&timeEnd=${timeEnd}&payment=${payment}`;

      const res = await axios.get(url);
      setData(res.data);
    };
    fethData();
  }, [payment, timeEnd, timeStart]);

  return (
    <div className="chart-admin w-100" style={{ overflow: "hidden" }}>
      <div className="d-flex jc-between al-center margin-bt-1">
        <h2 className="heading">กราฟ</h2>
      </div>
      <div className="d-flex jc-between al-center margin-bt-1">
        <FilterChart
          timeStart={setTimeStart}
          timeEnd={setTimeEnd}
          payment={setPayment}
        />
      </div>
      <CardHighlight
        url={`/analytics/all?timeStart=${timeStart}&timeEnd=${timeEnd}&payment=${payment}`}
      />
      <div className="d-flex w-100" style={{ boxSizing: "border-box" }}>
        <div
          className="d-flex gap-25px md-f-column w-100"
          style={{ marginTop: "1.5rem", maxWidth: "100%" }}
        >
          <div className="box-content w-100  md-w-auto">
            <div className="d-flex al-center jc-between container-header">
              <p className="title">Sales Analytics</p>
            </div>
            <div
              className="chart"
              style={{ padding: "1rem", boxSizing: "border-box" }}
            >
              <SalesAnalytics />
            </div>
          </div>
          <div className="box-content w-100 md-w-auto">
            <div className="d-flex al-center jc-between container-header">
              <p className="title">สถานะคำสั่งซื้อ</p>
            </div>
            <div
              className="chart"
              style={{ padding: "1rem", boxSizing: "border-box" }}
            >
              {data && data.length > 0 ? (
                <ChartStatusOrders dataValues={data} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="d-grid g-2 gap-25px md-g-1">
        <div className="table">
          <p className="title">สินค้ายอดนิยม</p>
          <table>
            <thead>
              <tr>
                <th>ชื่อสินค้า</th>
                <th>รายการขาย</th>
                <th>ยอดขาย</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data[9].map((data) => (
                  <tr key={data.productId}>
                    <td>{data?.name}</td>
                    <td>{formatNumber(data?.amount)}</td>
                    <td>฿{formatNumber(data?.amount * data?.price)}</td>
                  </tr>
                ))
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="table md-mt-0">
          <p className="title">หมวดหมู่ยอดนิยม</p>
          <table>
            <thead>
              <tr>
                <th>หมวดหมู่</th>
                <th>รายการขาย</th>
                <th>ยอดขาย</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data[8].map((data) => (
                  <tr key={data.cateId}>
                    <td>{data?.name}</td>
                    <td>{data?.length}</td>
                    <td>฿{formatNumber(data?.priceSum)}</td>
                  </tr>
                ))
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ChartAdmin;
