import { useEffect, useRef, useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import CardProduct from "../../components/cardProduct/CardProduct";
import "./product.css";
import { Carousel } from "antd";
import { useParams } from "react-router-dom";
import axios from "axios";
// redux
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeItem } from "../../redux/cartReducer";

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};
const Product = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(0);
  const slider = useRef(null);

  const reduxQuantityProducts = useSelector((state) => state.cart.products);

  const [product, setProduct] = useState([]);
  const [otherProduct, setOtherProduct] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/product/public/" + id);
        const resOther = await axios.get(
          "/product/public?cateId=" + res.data?.cateId + "&sortBy=RAND()&page=1"
        );

        setOtherProduct(resOther.data[1].data);
        setProduct(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    let isMatch = reduxQuantityProducts.find(
      (product) => product?.id === Number(id)
    );

    if (isMatch && isMatch?.quantity !== "" && isMatch?.quantity > 0) {
      setQuantity(isMatch?.quantity);
    } else {
      setQuantity(0);
    }
  }, [id, quantity, reduxQuantityProducts]);

  const handleAddToCart = () => {
    dispatch(
      addToCart({
        id: product.id,
        title: product?.name,
        img: process.env.REACT_APP_UPLOAD_URL + `/${product?.thumbnail}`,
        price: product?.discount > 0 ? product?.discount : product?.price,
        quantity: +1,
      })
    );
  };
  const handleRemoveFromCart = () => {
    dispatch(
      addToCart({
        id: product.id,
        title: product?.name,
        img: process.env.REACT_APP_UPLOAD_URL + `/${product?.thumbnail}`,
        price: product?.discount > 0 ? product?.discount : product?.price,
        quantity: -1,
      })
    );
  };

  return (
    <div>
      <div className="product-single">
        <div className="d-grid g-2 gap-25px md-g-1">
          <div className="thumbnail" id="thumbnail">
            {product?.discount && product?.discount !== undefined ? (
              <span className="onSale">Sale!</span>
            ) : (
              ""
            )}
            <Carousel ref={slider} dots={false}>
              <div className="d-flex al-center jc-center image">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL + `/${product?.thumbnail}`
                  }
                  alt=""
                />
              </div>
              {product?.gallery && product?.gallery.length > 0
                ? product?.gallery.map((p) => (
                    <div
                      className="d-flex al-center jc-center image"
                      key={p.id}
                    >
                      <img
                        src={process.env.REACT_APP_UPLOAD_URL + `/${p?.imgUrl}`}
                        alt=""
                      />
                    </div>
                  ))
                : ""}
            </Carousel>
            <button
              className="controller-btn d-flex al-center jc-center border-round prev"
              onClick={() => slider.current.next()}
            >
              <FaAngleLeft />
            </button>
            <button
              className="controller-btn d-flex al-center jc-center border-round next"
              onClick={() => slider.current.prev()}
            >
              <FaAngleRight />
            </button>
          </div>
          <div className="detail">
            <p className="name">{product?.name}</p>
            <div className="price al-end jc-start gap-small">
              {product?.discount > 0 && product?.soldout !== 1 ? (
                <>
                  <span className="newPrice">
                    ฿{formatNumber(product?.discount)}
                  </span>
                  <span className="beforeSale">
                    ฿{formatNumber(product?.price)}
                  </span>
                </>
              ) : product?.soldout !== 1 ? (
                <span className="newPrice">
                  ฿{formatNumber(product?.price)}
                </span>
              ) : (
                <span className="newPrice">-</span>
              )}
            </div>
            <p className="u-title">รายละเอียดสินค้า</p>
            <div className="desc">
              <p>{product?.description}</p>
            </div>
            <div className="d-flex al-center jc-start quantity mt-1">
              {product?.soldout === 1 ? (
                <div className="d-flex al-center jc-start quantity">
                  <p style={{ color: "#fff" }}>สินค้าหมด</p>
                </div>
              ) : (
                <div className="d-flex al-center jc-start quantity">
                  <button
                    className="d-flex al-center jc-center border-round minus"
                    onClick={() => {
                      if (quantity > 1) {
                        handleRemoveFromCart();
                      } else {
                        dispatch(removeItem(product?.id));
                      }
                    }}
                  >
                    <AiOutlineMinus />
                  </button>
                  <span>{quantity}</span>
                  <button
                    className="d-flex al-center jc-center border-round plus"
                    onClick={handleAddToCart}
                  >
                    <AiOutlinePlus />
                  </button>
                </div>
              )}
              {/* <button
                className="d-flex al-center jc-center border-round minus"
                onClick={() => {
                  if (quantity > 1) {
                    handleRemoveFromCart()
                  }else{
                    dispatch(removeItem(product?.id))
                  }
                }}
              >
                <AiOutlineMinus />
              </button>
              <span>{quantity}</span>
              <button
                className="d-flex al-center jc-center border-round plus"
                onClick={handleAddToCart}
              >
                <AiOutlinePlus />
              </button> */}
            </div>
            {/* <button className="mt-1 add-to-cart" onClick={handleAddToCart}>
              หยิบใส่ตะกร้า
            </button> */}
          </div>
        </div>
      </div>

      {/* other products */}
      <div className="mt-3 other-product">
        <h2 className="heading">สินค้าที่คล้ายกัน</h2>
        <div className="d-grid g-4 md-g-2 gap-1rem ">
          {otherProduct.length > 0
            ? otherProduct.map((p) => <CardProduct data={p} key={p.id} />)
            : ""}
        </div>
      </div>
    </div>
  );
};

export default Product;
