import { useEffect, useState } from "react";
import "./pagination.css";

const Pagination = ({ total, active, action, limit }) => {
  const [max, setmax] = useState(0);

  useEffect(() => {
    limit ? setmax(Math.ceil(total / limit)) : setmax(Math.ceil(total / 25));
  }, [limit, total]);

  const createBtn = (total) => {
    let btn = [];
    for (let i = 1; i <= total; i++) {
      btn.push(
        <li
          key={i}
          className={i === active ? "active" : ""}
          onClick={() => {
            action(i);
          }}
        >
          {i}
        </li>
      );
    }
    return btn;
  };

  return (
    <div className="pagination">
      <ul className="d-flex gap-1rem">
        {max > 1 ? createBtn(max) : ''}
      </ul>
    </div>
  );
};

export default Pagination;
