import './confirmOrder.css'
import StepCheckOut from '../../components/stepCheckOut/StepCheckOut'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
// import { useEffect } from 'react'
// import { useDispatch, useSelector } from "react-redux";
// import { removeItem } from "../../redux/cartReducer";
// import Moment from "react-moment";

const ConfirmOrder = () => {
  const {id} = useParams
  return (
    <div className='ConfirmOrder'>
        <p className="u-title">ยืนยันออเดอร์</p>
            <StepCheckOut step={3} />

            <div className="d-flex f-column al-center jc-center mt-2">
                <h2 style={{ marginBottom: '0' }}>ดำเนินการเสร็จสิ้น</h2>
                <div className="d-flex">
                    <span className='primary-color'>#{id}</span>
                </div>
                <AiOutlineCheckCircle className='large-icon' />
                <p>ขอบคุณที่ใช้บริการ</p>
            </div>
    </div>
  )
}

export default ConfirmOrder