import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { IoCloseSharp } from 'react-icons/io5'
import { RiImageAddFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/auth.service';

const BannerAdmin = () => {

  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();

  // check role
  useEffect(()=>{
    if(currentUser?.role === 3 ){
      navigate("/admin/orders");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentUser?.role])

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get('/banner')
        setData(res?.data);
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [])

  // thumbnail
  const [thumbnail, setThumbnail] = useState();
  const thumbnailChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setThumbnail(e.target.files[0]);
    }
  };

  const handleDel = async (id) => {
    try {
      const res = await axios.delete('/banner/' + id)
      if (res.status === 200) {
        window.location.reload();
      }
    } catch (err) {
      alert('เกิดข้อผิดพลาด!')
      console.log(err);
    }
  }

  const addBanner = async () => {
    try {
      let formData = new FormData();
      formData.append("banner", thumbnail);
      const res = await axios
        .post("/banner", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      if (res.status === 200) {
        window.location.reload();
      }
    } catch (err) {
      alert('เกิดข้อผิดพลาด!')
      console.log(err);
    }
  }

  return (
    <div>
      <div className="d-flex jc-start al-center">
        <h2 className="heading">จัดการแบนเนอร์</h2>
      </div>

      <div style={{
        display: 'grid',
        gridTemplateColumns: '1.5fr 1fr',
        gap: '1.5rem'
      }}>
        {/* table */}
        <div className="table">
          <p className="title">แบนเนอร์</p>
          <table style={{ height: 'fit-content' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>รูปภาพ</th>
                <th>คำสั่ง</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map(banner => (
                  <tr key={banner.id}>
                    <td>{banner?.id}</td>
                    <td>
                      <img style={{ width: '200px', height: 'auto' }} src={process.env.REACT_APP_UPLOAD_URL + `/${banner?.url}`} alt="" />
                    </td>
                    <td>
                      <div
                        className="d-flex al-center jc-center border-round icon"
                        onClick={
                          () => {
                            if (window.confirm("คุณต้องการลบผู้ใช้รายนี้หรือไม่?")) {
                              handleDel(banner?.id)
                            };
                          }
                        }
                        style={{
                          backgroundColor: 'var(--a-light-red-color)',
                          width: '30px',
                          height: '30px',
                          cursor: 'pointer',
                          transition: 'all .3s ease'
                        }}
                      >
                        <IoCloseSharp style={{ color: 'var(--a-red-color)' }} />
                      </div>
                    </td>
                  </tr>
                ))
              ) : <tr></tr>}
            </tbody>
          </table>
        </div>
        {/* add banner */}
        <div>
          <div style={{
            backgroundColor: '#fff',
            padding: '1.5rem',
            marginTop: '1.5rem',
            boxSizing: 'border-box',
            border: '1px solid var(--a-border-color)',
            borderRadius: '10px'
          }}>
            <p className="title" style={{
              fontSize: '1rem',
              fontWeight: '700',
              marginBottom: '.5rem',
              color: 'var(--a-blk-color)',
            }}>
              ภาพแบนเนอร์<span style={{ color: 'var(--a-red-color)' }}>*</span>
            </p>
            <label
              htmlFor="thumbnail"
              className="d-flex al-center jc-center thumbnail"
              style={{
                width: '100%',
                minHeight: '260px',
                backgroundColor: 'var(--a-bg-color)',
                borderRadius: '10px',
                marginBottom: '25px',
                overflow: 'hidden',
                cursor: 'pointer'
              }}
            >
              <input
                accept="image/*"
                type="file"
                onChange={thumbnailChange}
                id="thumbnail"
                style={{ display: "none" }}
              />
              {thumbnail === undefined ? (
                <div className="d-flex f-column al-center jc-center">
                  <RiImageAddFill style={{
                    fontSize: '5rem',
                    color: 'var(--a-p-color)'
                  }} />
                  <small>คลิ๊กเพื่อเพิ่มภาพสินค้า</small>
                </div>
              ) : (
                <img
                  src={URL.createObjectURL(thumbnail)}
                  alt="Thumb"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
            </label>
            <button style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '5px',
              padding: '.5rem .75rem',
              borderRadius: '5px',
              cursor: 'pointer',
              backgroundColor: 'var(--a-p-color)',
              color: 'white',
              minWidth: '85px',
              transition: 'all .3s ease',
              border: 'none',
              width: '100%'
            }}
              onClick={addBanner}>บันทึก</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerAdmin