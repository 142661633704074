import './dealOfDay.css'
// redux
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartReducer";
// import CountdownTimer from '../../CountdownTimer';
import { useCountdown } from '../../hook/useCountdown';

const formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    let splitArray = formetedNumber.split(".");
    if (splitArray.length > 1) {
        formetedNumber = splitArray[0];
    }
    return formetedNumber;
};


const DealOfDay = ({ data }) => {
    const dateEnd = new Date(data?.dayEnd).getTime()
    
    const [days, hours, minutes, seconds] = useCountdown(dateEnd);

    const dispatch = useDispatch();
    const handleAddToCart = () => {
        dispatch(
            addToCart({
                id: data.id,
                title: data?.name,
                img: process.env.REACT_APP_UPLOAD_URL + `/${data?.thumbnail}`,
                price: data?.promotion_onsale,
                quantity: 1,
            })
        );
    };


    return (
        days + hours + minutes + seconds <= 0 ? '' :
            <div className='d-flex al-center jc-center gap-25px md-f-column dealOfDay'>
                <div className="flex-2 d-flex al-center jc-center thumbnail">
                    <span className='onSale'>Sale!</span>
                    <img src={process.env.REACT_APP_UPLOAD_URL + `/${data?.thumbnail}`} alt="" />
                </div>
                <div className='flex-3'>
                    <p className="name">{data?.name}</p>
                    <div className='desc'>
                        <span>{data?.description}</span>
                    </div>

                    <div className="price d-flex al-end jc-start gap-1rem">
                        <span className="newPrice">฿{formatNumber(data?.promotion_onsale)}</span>
                        <span className="beforeSale">฿{formatNumber(data?.promotion_price)}</span>
                    </div>
                    <button className="add-to-cart" onClick={handleAddToCart}>หยิบใส่ตะกร้า</button>
                    <p className='mt-small'><b>รีบหน่อย! ข้อเสนอสิ้นสุดใน</b></p>
                    <div className="d-flex gap-1rem counter">
                        <div className='d-flex f-column al-center jc-center'>
                            <p>{days}</p>
                            <span>Days</span>
                        </div>
                        <div className='d-flex f-column al-center jc-center'>
                            <p>{hours}</p>
                            <span>Hours</span>
                        </div>
                        <div className='d-flex f-column al-center jc-center'>
                            <p>{minutes}</p>
                            <span>Min</span>
                        </div>
                        <div className='d-flex f-column al-center jc-center'>
                            <p>{seconds}</p>
                            <span>Sec</span>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default DealOfDay