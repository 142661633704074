import "./productsAdmin.css";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";

import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import axios from "axios";
import { Switch } from "antd";
import AuthService from "../../services/auth.service";

const ProductsAdmin = () => {
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();
    // check role
    useEffect(()=>{
      if(currentUser?.role !== 1 ){
        navigate("/admin/");
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentUser?.role])

  const [popupCate, setPopupCate] = useState(false);
  const [textOptionCate, setTextOptionCate] = useState("เลือกหมวดหมู่");

  const [popupStatus, setPopupStatus] = useState(false);
  const [textOptionStatus, setTextOptionStatus] = useState("เลือกสถานะ");

  const handleClickActionTable = (e) => {
    const parent = e.target.closest(".actions");
    const dropdown = parent.querySelector(".dropdown");

    dropdown.style.display === "none" || dropdown.style.display === ""
      ? (dropdown.style.display = "block")
      : (dropdown.style.display = "none");
  };

  // filter
  const [filterCate, setFilterCate] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  // category
  const [cate, setCate] = useState([]);
  useEffect(() => {
    const fetchCate = async () => {
      try {
        const res = await axios.get("/category/all");
        setCate(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCate();
  }, []);

  // Pagination
  const [countPagination, setCountPagination] = useState(0);
  const [activeBtn, setActiveBtn] = useState(1);

  // products
  const [products, setProducts] = useState([]);
  useEffect(() => {
    let url = "/product/admin?";
    filterStatus !== ""
      ? (url += `status=${filterStatus}`)
      : (url += `status=`);
    filterCate !== "" ? (url += `&cateId=${filterCate}`) : (url += `&cateId=`);
    filterStatus !== undefined || filterCate !== undefined
      ? (url += `&page=${activeBtn}`)
      : (url += `page=${activeBtn}`);

    const fetchProducts = async () => {
      try {
        const res = await axios.get(url);
        setProducts(res.data[1]?.data);
        setCountPagination(res.data[0]?.pagination);
      } catch (err) {
        console.log(err);
      }
    };

    fetchProducts();
  }, [filterStatus, filterCate, activeBtn]);

  const handleDel = async (id) => {
    try {
      await axios.delete("/product/" + id);
      setProducts(
        products.filter((product) => {
          return product !== products.find((p) => p.id === id);
        })
      );
    } catch (err) {
      alert("เกิดข้อผิดพลาด");
      console.log(err);
    }
  };

  
  const onChangeToggle = (checked, id) => {
    let status;
    checked ? (status = 0) : (status = 1);
    axios.put("/product/status/" + id, {
      status: status,
    });
  };


  return (
    <div className="products-admin">
      <div className="d-flex jc-between al-center margin-bt-1">
        <h2 className="heading">สินค้า</h2>
        <Link to="/admin/new-product">
          <button className="primary-button">
            <AiOutlinePlus /> เพิ่มสินค้า
          </button>
        </Link>
      </div>
      {/* Dropdown */}
      <div className="d-flex jc-between al-center gap-1rem">
        <div className="d-flex al-center jc-start gap-1rem">
          {/* cate */}
          <div
            className="d-flex al-center jc-end"
            style={{ position: "relative" }}
          >
            <div
              className="d-flex al-center jc-between option ml-0"
              onClick={() => {
                setPopupCate(true);
              }}
            >
              {textOptionCate} <RiArrowDropDownLine />
            </div>
            <div
              className="dropdown"
              style={{ display: popupCate ? "block" : "none" }}
              onClick={(e) => {
                setPopupCate(false);
              }}
            >
              <ul
                onClick={(e) => {
                  setTextOptionCate(e.target.innerText);
                }}
              >
                <li
                  onClick={() => {
                    setFilterCate("");
                    setActiveBtn(1);
                  }}
                  className={
                    textOptionCate === "เลือกหมวดหมู่" ? "link active" : "link"
                  }
                >
                  เลือกหมวดหมู่
                </li>
                {cate.length > 0
                  ? cate.map((c) => (
                      <li
                        key={c.id}
                        onClick={() => {
                          setFilterCate(c.id);
                          setActiveBtn(1);
                        }}
                        className={
                          textOptionCate === c.name ? "link active" : "link"
                        }
                      >
                        {c.name}
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
          </div>
          {/* status */}
          <div
            className="d-flex al-center jc-end md-hide"
            style={{ position: "relative" }}
          >
            <div
              className="d-flex al-center jc-between option ml-0"
              onClick={() => {
                setPopupStatus(true);
              }}
            >
              {textOptionStatus} <RiArrowDropDownLine />
            </div>
            <div
              className="dropdown"
              style={{ display: popupStatus ? "block" : "none" }}
              onClick={(e) => {
                setPopupStatus(false);
              }}
            >
              <ul
                onClick={(e) => {
                  setTextOptionStatus(e.target.innerText);
                }}
              >
                <li
                  onClick={() => {
                    setFilterStatus("");
                  }}
                  className={
                    textOptionStatus === "เลือกหมวดหมู่"
                      ? "link active"
                      : "link"
                  }
                >
                  เลือกสถานะ
                </li>
                <li
                  onClick={() => {
                    setFilterStatus(1);
                  }}
                  className={
                    textOptionStatus === "เผยแพร่" ? "link active" : "link"
                  }
                >
                  เผยแพร่
                </li>
                <li
                  onClick={() => {
                    setFilterStatus(0);
                  }}
                  className={
                    textOptionStatus === "แบบร่าง" ? "link active" : "link"
                  }
                >
                  แบบร่าง
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <input type="number" className='list-per-page' placeholder='จำนวนสินค้า/หน้า' /> */}
      </div>

      {/* table */}
      <div className="table">
        <p className="title">สินค้าทั้งหมด ({products.length})</p>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>รูปภาพ</th>
              <th>ชื่อสินค้า</th>
              <th>หมวดหมู่สินค้า</th>
              <th>สถานะ</th>
              <th>ราคา</th>
              <th>ยังมีสินค้า</th>
              <th>คำสั่ง</th>
            </tr>
          </thead>
          <tbody>
            {products.length > 0 ? (
              products.map((p) => (
                <tr key={p.id}>
                  <td>{p.id}</td>
                  <td>
                    <div className="d-flex al-center jc-start">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL + `/${p?.thumbnail}`
                        }
                        alt=""
                      />
                    </div>
                  </td>
                  <td>{p?.name}</td>
                  <td>
                    {p?.category?.name !== undefined ? p?.category?.name : "-"}
                  </td>
                  <td>
                    {p?.status === 1 ? (
                      <span className="status-complete">เผยแพร่</span>
                    ) : (
                      <span className="status-pending">แบบร่าง</span>
                    )}
                  </td>
                  <td>
                    {p?.discount !== null ? (
                      <>
                        <span style={{ textDecoration: "line-through" }}>
                          {p?.price}฿
                        </span>
                        <span style={{ paddingLeft: "10px" }}>
                          {p?.discount}฿
                        </span>
                      </>
                    ) : (
                      `${p?.price}฿`
                    )}
                  </td>
                  <td>
            
                    <Switch
                      defaultChecked={p.soldout === 1 ? false : true}
                      onChange={(e) => {
                        onChangeToggle(e, p.id);
                      }}
                    />
        
                  </td>
                  <td>
                    <div className="actions">
                      <BsThreeDots
                        onClick={(e) => {
                          handleClickActionTable(e);
                        }}
                      />
                      <div className="dropdown">
                        <ul
                          onClick={(e) => {
                            handleClickActionTable(e);
                          }}
                        >
                          <li className="link">
                            <Link to={"/product/" + p.id}>รายละเอียด</Link>
                          </li>
                          <li>
                            <Link
                              to={"/admin/update-product/" + p.id}
                              className="link"
                            >
                              แก้ไข
                            </Link>
                          </li>
                          <li
                            className="link red-color"
                            onClick={() => {
                              handleDel(p.id);
                            }}
                          >
                            ลบ
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        total={countPagination}
        active={activeBtn}
        action={setActiveBtn}
      />
    </div>
  );
};

export default ProductsAdmin;
