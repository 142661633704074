import "./promotionsAdmin.css";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import AddDeal from "../../components/addDeal/AddDeal";
import FlashDealAdmin from "../../components/flashDealAdmin/FlashDealAdmin";
import axios from "axios";
import Moment from "react-moment";
import AuthService from "../../services/auth.service";

const PromotionsAdmin = () => {
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();

  // check role
  useEffect(()=>{
    if(currentUser?.role === 3 ){
      navigate("/admin/orders");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentUser?.role])

  const [popupCate, setPopupCate] = useState(false);
  const [textOptionCate, setTextOptionCate] = useState("เลือกหมวดหมู่");

  const [showAddProduct, setShowAddProduct] = useState(false);

  const handleClickActionTable = (e) => {
    const parent = e.target.closest(".actions");
    const dropdown = parent.querySelector(".dropdown");

    dropdown.style.display === "none" || dropdown.style.display === ""
      ? (dropdown.style.display = "block")
      : (dropdown.style.display = "none");
  };

  // filter
  const [filterCate, setFilterCate] = useState("");

  // category
  const [cate, setCate] = useState([]);
  useEffect(() => {
    const fetchCate = async () => {
      const res = await axios.get("/category/all");
      setCate(res.data);
    };
    fetchCate();
  }, []);

  const [textOptionCateFlash] = useState("เลือกหมวดหมู่");

  // products

  const [products, setProducts] = useState([]);

  useEffect(() => {
    let url = "/deal";
    filterCate !== "" ? (url += `?cateId=${filterCate}`) : (url += `?cateId=`);

    const fetchProducts = async () => {
      try {
        const res = await axios.get(url);
        setProducts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProducts();
  }, [filterCate]);

  const handleDelete = async (id) => {
    try {
      await axios.delete("/deal/" + id);

      setProducts(
        products.filter((p) => {
          return p !== products.find((c) => c.promotion_id === id);
        })
      );

      alert("สำเร็จ");
    } catch (err) {
      alert("เกิดข้อผิดพลาด");
      console.log(err);
    }
  };

  return (
    <div className="promotion-manager">
      <div className="d-flex jc-between al-center margin-bt-1">
        <h2 className="heading">โปรโมชั่น</h2>
        <button
          className="primary-button"
          onClick={() => {
            setShowAddProduct(true);
          }}
        >
          <AiOutlinePlus /> Deal Of The Day
        </button>
      </div>

      {/* Deal Of The Day */}
      {/* add Product */}
      <AddDeal show={showAddProduct} setShow={setShowAddProduct} />
      <div
        className={showAddProduct ? "overlay show" : "overlay"}
        onClick={() => {
          setShowAddProduct(false);
        }}
      ></div>

      {/* Dropdown */}
      <div className="d-flex jc-between al-center gap-1rem">
        <div className="d-flex al-center jc-start gap-1rem">
          {/* cate */}
          <div
            className="d-flex al-center jc-end"
            style={{ position: "relative" }}
          >
            <div
              className="d-flex al-center jc-between option ml-0"
              onClick={() => {
                setPopupCate(true);
              }}
            >
              {textOptionCate} <RiArrowDropDownLine />
            </div>
            <div
              className="dropdown"
              style={{ display: popupCate ? "block" : "none" }}
              onClick={(e) => {
                setPopupCate(false);
              }}
            >
              <ul
                onClick={(e) => {
                  setTextOptionCate(e.target.innerText);
                }}
              >
                <li
                  className={
                    textOptionCateFlash === "เลือกหมวดหมู่"
                      ? "link active"
                      : "link"
                  }
                  onClick={() => {
                    setFilterCate("");
                  }}
                >
                  เลือกหมวดหมู่
                </li>
                {cate.length > 0
                  ? cate.map((c) => (
                      <li
                        key={c.id}
                        className={
                          textOptionCateFlash === `${c.name}`
                            ? "link active"
                            : "link"
                        }
                        onClick={() => {
                          setFilterCate(c.id);
                        }}
                      >
                        {c.name}
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* table */}
      <div className="table">
        <p className="title">กำลังลดราคา</p>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>รูปภาพ</th>
              <th>ชื่อสินค้า</th>
              <th>หมวดหมู่สินค้า</th>
              <th>ราคาสินค้า</th>
              <th>ราคาเซลล์</th>
              <th>วันเริ่มต้น</th>
              <th>วันสิ้นสุด</th>
              <th>คำสั่ง</th>
            </tr>
          </thead>
          <tbody>
            {products.length > 0 ? (
              products.map((p) => (
                <tr key={p.promotion_id}>
                  <td>{p.promotion_id}</td>
                  <td>
                    <div className="d-flex al-center jc-start">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL + `/${p?.thumbnail}`
                        }
                        alt=""
                      />
                    </div>
                  </td>
                  <td>{p.name}</td>
                  <td>
                    {p?.category?.name !== undefined ? p?.category?.name : "-"}
                  </td>
                  <td>
                    {p?.promotion_price !== null
                      ? `${p?.promotion_price}฿`
                      : "-"}
                  </td>
                  <td>
                    {p?.promotion_onsale !== null
                      ? `${p?.promotion_onsale}฿`
                      : "-"}
                  </td>
                  {/* <td>{p?.dayStart !== null ? p?.dayStart : '-'}</td> */}
                  <td>
                    {p?.dayStart !== null ? (
                      <Moment format="DD-MM-YYYY">{p?.dayStart}</Moment>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {p?.dayEnd !== null ? (
                      <Moment format="DD-MM-YYYY">{p?.dayEnd}</Moment>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    <div className="actions">
                      <BsThreeDots
                        onClick={(e) => {
                          handleClickActionTable(e);
                        }}
                      />
                      <div className="dropdown">
                        <ul
                          onClick={(e) => {
                            handleClickActionTable(e);
                          }}
                        >
                          <li>
                            <Link
                              to={"/admin/update-deal/" + p.promotion_id}
                              className="link"
                            >
                              แก้ไข
                            </Link>
                          </li>
                          <li
                            className="link red-color"
                            onClick={() => {
                              handleDelete(p.promotion_id);
                            }}
                          >
                            ลบ
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </div>

      <FlashDealAdmin />
    </div>
  );
};

export default PromotionsAdmin;
