import cartReducer from './cartReducer'
import cateReducer from './cateReducer'
import paymentReducer from './paymentReducer'
import customer from './customerReducer'

import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = { key: 'root', version: 1, storage }
const x = { key: 'x', version: 1, storage }
const persistedReducer = persistReducer(x, cartReducer)
const paymentpersistedReducer = persistReducer(persistConfig, paymentReducer)
const customerPersistedReducer = persistReducer(persistConfig, customer)

export const store = configureStore({
    reducer: {
        cart: persistedReducer,
        cate: cateReducer,
        payment: paymentpersistedReducer,
        customer: customerPersistedReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
})
export let persistor = persistStore(store); 