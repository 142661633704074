import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import "./bestSeller.css";

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};

const BestSeller = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const res = await axios.get("/analytics/bestSellerUser");
        setData(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchApi();
  }, []);

  return (
    <div className="box-content bestSeller md-w-100">
      <p className="title">Best Sellers</p>
      <ul>
        {data && data.length > 0 ? (
          data.map((d) => (
            <li className="d-flex al-start jc-start list gap-1rem pd-d5rem-1rem" key={d.id}>
              <div className="border-round-5px img">
                <img
                  src={d?.pictureUrl}
                  alt=""
                />
              </div>
              <div className="d-flex al-center jc-between w-100  gap-small">
                <div className="d-flex f-column">
                  <p className="name">{d?.displayName}</p>
                  <span className="order">ยอดรวมสั่งซื้อ</span>
                </div>
                <p className="total">฿{formatNumber(d?.orderData?.totalprice)}</p>
              </div>
            </li>
          ))
        ) : (
          <li></li>
        )}
      </ul>
    </div>
  );
};

export default BestSeller;
