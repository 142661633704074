import "./cardProduct.css";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeItem } from "../../redux/cartReducer";
import { useEffect, useState } from "react";

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};

const CardProduct = ({ data }) => {
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(0);

  const reduxQuantityProducts = useSelector((state) => state.cart.products);

  useEffect(() => {
    let isMatch = reduxQuantityProducts.find(
      (products) => products.id === data.id
    );

    if (isMatch && isMatch?.quantity !== "" && isMatch?.quantity > 0) {
      setQuantity(isMatch?.quantity);
    } else {
      setQuantity(0);
    }
  }, [data.id, reduxQuantityProducts]);

  const handleAddToCart = () => {
    dispatch(
      addToCart({
        id: data.id,
        title: data?.name,
        img: process.env.REACT_APP_UPLOAD_URL + `/${data?.thumbnail}`,
        price: data?.discount > 0 ? data?.discount : data?.price,
        quantity: +1,
      })
    );
  };
  const handleRemoveFromCart = () => {
    dispatch(
      addToCart({
        id: data.id,
        title: data?.name,
        img: process.env.REACT_APP_UPLOAD_URL + `/${data?.thumbnail}`,
        price: data?.discount > 0 ? data?.discount : data?.price,
        quantity: -1,
      })
    );
  };

  return (
    <div className="CardProduct">
      <Link to={"/product/" + data?.id} className="thumbnail">
        {data?.discount && data?.discount !== undefined ? (
          <span className="onSale">ลดราคา</span>
        ) : (
          ""
        )}
        <img
          style={{
            filter: data?.soldout === 1 ? "grayscale(0.85) saturate(0.5)" : "",
            opacity: data?.soldout === 1 ? "0.35" : "",
          }}
          src={process.env.REACT_APP_UPLOAD_URL + `/${data?.thumbnail}`}
          alt=""
        />
      </Link>
      <div className="p-1rem detail">
        <Link to={"/product/" + data?.id} className="mt-0 name">
          {data?.name}
        </Link>
        <div className="price al-end jc-start gap-small">
          {data?.discount > 0 && data?.soldout !== 1 ? (
            <>
              <span className="newPrice">฿{formatNumber(data?.discount)}</span>
              <span className="beforeSale">฿{formatNumber(data?.price)}</span>
            </>
          ) : data?.soldout !== 1 ? (
            <span className="newPrice">฿{formatNumber(data?.price)}</span>
          ) : (
            <span className="newPrice">-</span>
          )}
        </div>
        {data?.soldout === 1 ? (
          <div className="d-flex al-center jc-start quantity">
            <p style={{ color: "#fff" }}>สินค้าหมด</p>
          </div>
        ) : (
          <div className="d-flex al-center jc-start quantity">
            <button
              className="d-flex al-center jc-center border-round minus"
              onClick={() => {
                if (quantity > 1) {
                  handleRemoveFromCart();
                } else {
                  dispatch(removeItem(data?.id));
                }
              }}
            >
              <AiOutlineMinus />
            </button>
            <span>{quantity}</span>
            <button
              className="d-flex al-center jc-center border-round plus"
              onClick={handleAddToCart}
            >
              <AiOutlinePlus />
            </button>
          </div>
        )}

        {/* <button className="add-to-cart" onClick={handleAddToCart}>
          หยิบใส่ตะกร้า
        </button> */}
      </div>
    </div>
  );
};

export default CardProduct;
