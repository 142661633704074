import NavAdmin from '../navAdmin/NavAdmin'
import './sidebarAdminMobile.css'
import { BsDoorOpenFill } from 'react-icons/bs'
import AuthService from "../../services/auth.service";

const SidebarAdminMobile = ({ click, setClick }) => {
  // const user = AuthService.getCurrentUser();
  
  const logout = async () => {
    AuthService.logout().then(
      () => {
        window.location.reload();
      },
      (error) => {
        alert("เกิดข้อผิดพลาด!");
        console.log(error);
      }
    );
  }

  return (
    <>
      <div className='aSidebar-md d-flex f-column jc-between'
        style={{ height: '100%', left: click ? '0' : '-100%' }}
      >
        <div onClick={() => { setClick(prev => !prev) }}>
          <div className="d-flex al-center jc-start userInfo">
            <div className="border-round avatar">
              <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/avatar.jpg'} alt="" />
            </div>
            <span className='username'>Admin</span>
          </div>
          <NavAdmin />
        </div>
        <div className="logout">
          <ul>
            <li className='red-color'  onClick={logout}><BsDoorOpenFill /> ออกจากระบบ</li>
          </ul>
        </div>
      </div>
      <div className={click ? "overlay show" : 'overlay'} onClick={() => { setClick(prev => !prev) }}></div>
    </>
  )
}

export default SidebarAdminMobile