import "./sidebarCategoryMobile.css";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
// redux
import { useDispatch } from "react-redux";
import { changeName } from "../../redux/cateReducer";

const SidebarCategoryMobile = ({ show }) => {
  const dispatch = useDispatch();

  const showSubMenu = (elem) => {
    const parent = elem.target.closest("li");
    const isMatch = parent.className.includes("active");

    if (isMatch) {
      parent.classList.remove("active");
      parent.querySelector(".sub-menu").style.height = `0`;
    } else {
      parent.classList.add("active");
      let height = 0;
      const childElem = parent
        .querySelector(".sub-menu")
        .querySelectorAll("li");
      childElem.forEach((element) => {
        height += element.offsetHeight;
      });

      parent.querySelector(".sub-menu").style.height = `${height}px`;
    }
  };

  const [cate, setCate] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/category"
        );
        setCate(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  return (
    <div
      // className={show ? "SidebarCategoryMobile show" : "SidebarCategoryMobile"}
      className="SidebarCategoryMobile"
    >
      <Link to={"products"} style={{
        display: 'block',
        width: '100%',
        color: 'var(--gray-color)',
        padding: '.35rem 0',
        marginBottom: '3px'
      }}>สินค้าทั้งหมด</Link>
      <p className="u-title">หมวดหมู่สินค้า</p>
      <div className="sidebar">
        <ul>
          {cate.length > 0
            ? cate.map((cate) => (
              <li key={cate.id}>
                <Link
                  to={"category/" + cate.id}
                  onClick={() => {
                    dispatch(changeName(cate.name));
                  }}
                >
                  {cate.name}
                </Link>

                {/* submenu */}
                {cate?.subMenu.length > 0 ? (
                  <>
                    <AiOutlinePlus
                      className="default"
                      onClick={(e) => {
                        showSubMenu(e);
                      }}
                    />
                    <AiOutlineMinus
                      className="check"
                      onClick={(e) => {
                        showSubMenu(e);
                      }}
                    />
                    <ul className="sub-menu">
                      {cate?.subMenu.map((sub) => (
                        <li key={sub.id}>
                          <Link
                            to={"category/" + sub.id}
                            onClick={() => {
                              dispatch(changeName(sub.name));
                            }}
                          >
                            {sub.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  ""
                )}
              </li>
            ))
            : ""}
        </ul>
      </div>
    </div>
  );
};

export default SidebarCategoryMobile;
