import StepCheckOut from '../../components/stepCheckOut/StepCheckOut'
import './completeOrder.css'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { removeItem } from "../../redux/cartReducer";
import moment from "moment";
// import thLocale from "moment/locale/th";

import Liff from '../../components/liff/Liff'
import axios from 'axios'

const formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    let splitArray = formetedNumber.split(".");
    if (splitArray.length > 1) {
        formetedNumber = splitArray[0];
    }
    return formetedNumber;
};

const CompleteOrder = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [data, setData] = useState(null)

    const products = useSelector((state) => state.cart.products);

    useEffect(() => {
        products.forEach(item => {
            dispatch(removeItem(item?.id))
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const fetchData = async (callback) => {
            const res = await axios.get('/order/' + id);
            let data = res.data[0];


            let totalProduct  = 0;
            data?.products.forEach(p=>{
                totalProduct+=p?.quantity
            })


            // setData(res.data[0])

            let paymentPrice = 0
            const handleCod = () => {
                if (totalProduct <= 3) {
                    paymentPrice = 50
                } else if (totalProduct <= 6) {
                    paymentPrice = 70
                }
                else if (totalProduct <= 10) {
                    paymentPrice = 90
                } else {
                    paymentPrice = 10 * totalProduct
                }
            }

            const handleEms = () => {
                if (totalProduct <= 2) {
                    paymentPrice = 50
                } else {
                    paymentPrice = 0
                }
            }

            // check payment
            if (data?.payment === "เก็บเงินปลายทาง") {
                handleCod()
            } else {
                handleEms()
            }


            let flex = {
                type: "flex",
                altText: "รายการสั่งซื้อ",
                contents:
                {
                    type: "bubble",
                    direction: "ltr",
                    body: {
                        type: "box",
                        layout: "vertical",
                        contents: [
                            {
                                type: "text",
                                text: "สรุปรายการสั่งซื้อ",
                                weight: "bold",
                                color: "#18C94F",
                                align: "start",
                                contents: []
                            },
                            {
                                type: "text",
                                text: "บุหรี่พลัส BURI+",
                                weight: "bold",
                                size: "xl",
                                margin: "sm",
                                contents: []
                            },
                            {
                                type: "text",
                                text: `${moment(res.data[0]?.createdAt).format("LLL")} น.`,
                                size: "xs",
                                color: "#B3B3B3FF",
                                contents: []
                            },
                            {
                                type: "separator",
                                margin: "lg"
                            },
                        ]
                    },
                }

            };

            // flex products
            if (data?.products.length > 0) {
                data?.products.forEach((p, index) => {
                    if (index === 0) {
                        flex.contents.body.contents.push({
                            type: "box",
                            layout: "horizontal",
                            paddingTop: "15px",
                            contents: [
                                {
                                    type: "text",
                                    text: `${p.name} x ${p.quantity}`,
                                    contents: [],
                                    wrap: true,
                                },
                                {
                                    type: "text",
                                    text: `${formatNumber(p.discount && p.discount > 0 ? p.discount : p.price)} บาท`,
                                    align: "end",
                                    contents: [],
                                    wrap: true,
                                }
                            ]
                        })
                    } else if (data?.products.length - 1 === index) {
                        flex.contents.body.contents.push({
                            type: "box",
                            layout: "horizontal",
                            paddingBottom: "15px",
                            contents: [
                                {
                                    type: "text",
                                    text: `${p.name} x ${p.quantity}`,
                                    contents: [],
                                    wrap: true,
                                },
                                {
                                    type: "text",
                                    text: `${formatNumber(p.discount && p.discount > 0 ? p.discount : p.price)} บาท`,
                                    align: "end",
                                    contents: []
                                }
                            ]
                        })
                    } else {
                        flex.contents.body.contents.push({
                            type: "box",
                            layout: "horizontal",
                            contents: [
                                {
                                    type: "text",
                                    text: `${p.name} x ${p.quantity}`,
                                    contents: []
                                },
                                {
                                    type: "text",
                                    text: `${formatNumber(p.discount && p.discount > 0 ? p.discount : p.price)} บาท`,
                                    align: "end",
                                    contents: []
                                }
                            ]
                        })
                    }

                })
            }

            // bottom flex
            flex.contents.body.contents.push(
                {
                    type: "separator"
                },
                {
                    type: "box",
                    layout: "horizontal",
                    paddingTop: "15px",
                    contents: [
                        {
                            type: "text",
                            text: `${data?.payment}`,
                            contents: []
                        },
                        {
                            type: "text",
                            text: `${paymentPrice} บาท`,
                            align: "end",
                            contents: []
                        }
                    ]
                },
                {
                    type: "box",
                    layout: "horizontal",
                    contents: [
                        {
                            type: "text",
                            text: "จำนวนสินค้า",
                            contents: []
                        },
                        {
                            type: "text",
                            text: `${totalProduct} ชิ้น`,
                            align: "end",
                            contents: []
                        }
                    ]
                },
                {
                    type: "box",
                    layout: "horizontal",
                    paddingBottom: "15px",
                    contents: [
                        {
                            type: "text",
                            text: "รวมเป็นเงิน",
                            weight: "bold",
                            contents: []
                        },
                        {
                            type: "text",
                            text: `${formatNumber(data.total)} บาท`,
                            weight: "bold",
                            align: "end",
                            contents: []
                        }
                    ]
                },
                {
                    type: "separator"
                },
                {
                    type: "box",
                    layout: "horizontal",
                    paddingTop: "15px",
                    contents: [
                        {
                            type: "text",
                            text: "",
                            size: "xs",
                            color: "#B3B3B3FF",
                            contents: []
                        },
                        {
                            type: "text",
                            text: `#${id}`,
                            color: "#B3B3B3FF",
                            align: "end",
                            contents: []
                        }
                    ]
                }
            )

            setData(flex)
        }

        fetchData();
    }, [id])


    return (
        <div className='completeOrder'>
            {data !== null ? <Liff flexContents={data} /> : ''}
            <p className="u-title">ยืนยันออเดอร์</p>
            <StepCheckOut step={3} />

            <div className="d-flex f-column al-center jc-center mt-2">
                <h2 style={{ marginBottom: '0' }}>ดำเนินการเสร็จสิ้น</h2>
                <div className="d-flex">
                    <span><b>หมายเลขคำสั่งซื้อ </b></span><span className='primary-color' style={{ marginLeft: '5px' }}>#{id}</span>
                </div>
                <AiOutlineClockCircle className='large-icon' />
                <p>กรุณารอการยืนยันจากเจ้าหน้าที่</p>
            </div>

        </div>
    )
}

export default CompleteOrder