import "./dashboardAdmin.css";
import { AiOutlinePlus } from "react-icons/ai";
// import { RiArrowDropDownLine } from 'react-icons/ri'

import CardHighlight from "../../components/cardHighlight/CardHighlight";
import SalesAnalytics from "../../components/salesAnalytics/SalesAnalytics";
import BestSeller from "../../components/bestSeller/BestSeller";
import { useEffect, useState } from "react";
import LastOrder from "../../components/lastOrder/LastOrder";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import FilterChart from "../../components/filterChart/FilterChart";

const DashboardAdmin = () => {
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();

  // check role
  useEffect(() => {
    if (currentUser?.role === 3) {
      navigate("/admin/orders");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.role]);

  const [timeStart, setTimeStart] = useState("");
  const [timeEnd, setTimeEnd] = useState("");
  const [payment, setPayment] = useState("");

  return (
    <div className="dashboard-admin">
      <div className="d-flex jc-between al-center margin-bt-1">
        <h2 className="heading">แดชบอร์ด</h2>
        <Link to="/admin/new-product">
          <button className="primary-button">
            <AiOutlinePlus /> เพิ่มสินค้า
          </button>
        </Link>
      </div>
      <div className="d-flex jc-between al-center margin-bt-1">
        <FilterChart
          timeStart={setTimeStart}
          timeEnd={setTimeEnd}
          payment={setPayment}
        />
      </div>
      <CardHighlight
        url={`/analytics/all?timeStart=${timeStart}&timeEnd=${timeEnd}&payment=${payment}`}
      />
      <div
        className="d-flex w-100 gap-25px md-f-column"
        style={{ marginTop: "1.5rem" }}
      >
        <div className="box-content w-100  md-w-auto">
          <div className="d-flex al-center jc-between container-header">
            <p className="title">Sales Analytics</p>
            <div
              className="d-flex al-center jc-end"
              style={{ position: "relative" }}
            >
              {/* <span>เรียงตาม</span>
              <div className='d-flex al-center jc-between option' onClick={() => { setPopupChart(true) }}>{textOptionChart}  <RiArrowDropDownLine /></div>
              <div className="dropdown" style={{ display: popupChart ? 'block' : 'none' }} onClick={(e) => { setPopupChart(false); }}>
                <ul onClick={(e) => { setTextOptionChart(e.target.innerText) }}>
                  <li className={textOptionChart === 'วันนี้' ? 'link active' : 'link'}>วันนี้</li>
                  <li className={textOptionChart === 'สัปดาห์นี้' ? 'link active' : 'link'}>สัปดาห์นี้</li>
                  <li className={textOptionChart === 'เดือนนี้' ? 'link active' : 'link'}>เดือนนี้</li>
                  <li className={textOptionChart === 'ปีนี้' ? 'link active' : 'link'}>ปีนี้</li>
                </ul>
              </div> */}
            </div>
          </div>
          <div
            className="chart"
            style={{ padding: "1rem", boxSizing: "border-box" }}
          >
            <SalesAnalytics />
          </div>
        </div>
        <BestSeller />
      </div>
      <LastOrder />
    </div>
  );
};

export default DashboardAdmin;
