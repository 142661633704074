import './mobileNav.css'
// import { IoGridOutline, IoHomeOutline, IoMenuOutline } from 'react-icons/io5'
// import { AiOutlineShopping } from 'react-icons/ai'
import SidebarCart from '../sidebarCart/SidebarCart'
import { useState } from 'react'
import SidebarMenuMobile from '../sidebarMenuMobile/SidebarMenuMobile'
// import { Link } from 'react-router-dom'
// import SidebarCategoryMobile from '../sidebarCategoryMobile/SidebarCategoryMobile'
import { NavLink } from "react-router-dom"
import { AiOutlineHome, AiOutlineShoppingCart,AiOutlineLike} from 'react-icons/ai'


const MobileNav = () => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [showCart, setShowCart] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    // const [showCategory, setShowCategory] = useState(false);

    const hideAll = () => {
        setShowOverlay(false);
        setShowCart(false);
        setShowMenu(false);
        // setShowCategory(false);
    }

    return (
        <>
            {/* <div className='mobileNav d-flex al-center jc-around'> */}
            <div className='mobileNav d-flex al-center jc-start' style={{ paddingLeft: '5px' }}>
                {/* <button className='d-flex al-center jc-center' onClick={() => { setShowMenu(true); setShowOverlay(true) }}><IoMenuOutline /></button> */}
                <nav className='user main d-flex al-center jc-center w-100'>
                    <ul className='d-flex al-center jc-around'>
                        <li><NavLink className="list" to="/" style={{ color: '#000', margin: '0', display:'flex', justifyContent: 'flex-start', gap: '10px', alignItems: 'center' }} ><AiOutlineHome /> หน้าแรก</NavLink></li>
                        <li onClick={() => { setShowMenu(true); setShowOverlay(true) }} style={{ color: '#000', margin: '0', display:'flex', justifyContent: 'flex-start', gap: '10px', alignItems: 'center', fontWeight: '600' }}><AiOutlineShoppingCart /> สินค้า</li>
                        {/* <li><NavLink className="list" to="/how-to-order" style={{color: '#000', margin: '0', display:'flex', justifyContent: 'flex-start', gap: '10px', alignItems: 'center'}}>วิธีการสั่งซื้อ</NavLink></li> */}
                        <li><NavLink className="list" to="/review" style={{ color: '#000', margin: '0', display:'flex', justifyContent: 'flex-start', gap: '10px', alignItems: 'center' }}><AiOutlineLike /> รีวิวจากลูกค้า</NavLink></li>
                        {/* <li><NavLink className="list" to="/payment">การชำะเงิน</NavLink></li>
                        <li><NavLink className="list" to="/contact-us">ติดต่อเรา</NavLink></li> */}
                    </ul>
                </nav>
                {/* <button className='d-flex al-center jc-center'><Link to="/"><IoHomeOutline /></Link></button> */}
                {/* <button className='d-flex al-center jc-center' onClick={() => { setShowCart(true); setShowOverlay(true) }}><AiOutlineShopping /><span className='d-flex al-center jc-center border-round count'>50</span></button> */}
                {/* <button className='d-flex al-center jc-center'  onClick={() => { setShowCategory(true); setShowOverlay(true) }}><IoGridOutline /></button> */}
            </div>
            <SidebarCart show={showCart} />
            <SidebarMenuMobile show={showMenu} />
            {/* <SidebarCategoryMobile show={showCategory} /> */}
            {showOverlay ? (<div className='u-overlay show' onClick={() => { hideAll() }}></div>) : ''}
        </>
    )
}

export default MobileNav