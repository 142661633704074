import NavAdmin from '../navAdmin/NavAdmin'
import './sidebarAdmin.css'

const SidebarAdmin = () => {
  return (
    <div className='sidebarAdmin'>
      <NavAdmin />
    </div>
  )
}

export default SidebarAdmin