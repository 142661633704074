import './userDetailAdmin.css'
import { AiOutlineFileText } from 'react-icons/ai'
import { BsThreeDots } from 'react-icons/bs'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Moment from 'react-moment'
import { useEffect, useState } from 'react'
import axios from 'axios'
import AuthService from '../../services/auth.service'

const formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    let splitArray = formetedNumber.split(".");
    if (splitArray.length > 1) {
        formetedNumber = splitArray[0];
    }
    return formetedNumber;
};

const UserDetailAdmin = () => {
    const { id } = useParams();
    const currentUser = AuthService.getCurrentUser();
    const navigate = useNavigate();
  
    // check role
    useEffect(()=>{
      if(currentUser?.role === 3 ){
        navigate("/admin/orders");
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentUser?.role])

    const handleClickActionTable = (e) => {
        const parent = e.target.closest('.actions')
        const dropdown = parent.querySelector('.dropdown');

        dropdown.style.display === 'none' || dropdown.style.display === '' ? dropdown.style.display = 'block' : dropdown.style.display = 'none'
    }

    const [customer, setCustomer] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get('/customer/u/' + id);

                setCustomer(res.data[0]);
            } catch (err) {
                console.log(err);
            }
        }
        fetchData();
    }, [id])


    const [lastOrder, setLastOrder] = useState([]);
    useEffect(() => {
        const fetchDataOrder = async () => {
            try {
                const res = await axios.get('/order/u/' + id);
                setLastOrder(res.data);
            } catch (err) {
                console.log(err);
            }
        }
        fetchDataOrder();
    }, [id])

    const handleDelOrder = async (id) => {
        try {
            await axios.delete('/order/' + id);
            const listOrder = lastOrder.filter(order => (order.id !== id))
            setLastOrder(listOrder)
        } catch (err) {
            alert('เกิดข้อผิดพลาด');
            console.log(err)
        }
    }
    
    return (
        <div className='user-detail-admin'>
            <div className="d-flex jc-start al-center margin-bt-1">
                <h2 className="heading">ข้อมูลลูกค้า</h2>
            </div>

            <div className='container'>
                <div className="d-flex al-start jc-between header">
                    <div>
                        <p><b>ข้อมูลลูกค้า #{id}</b></p>
                        <p className='date'>สร้างเมื่อ {<Moment format="DD-MM-YYYY เวลา HH:mm:ss">{customer?.createdAt}</Moment>} น. / แก้ไขล่าสุด {<Moment format="DD-MM-YYYY เวลา HH:mm:ss">{customer?.updatedAt}</Moment>} น.</p>
                    </div>
                    <div className='d-flex al-center jc-center actions'>
                        <Link to={'/admin/update-user/' + id}><button className='d-flex al-center jc-center'>แก้ไข</button></Link>
                    </div>
                </div>

                {/* content */}
                <div className="d-grid g-2 gap-25px detail-content md-g-1">
                    <div className="d-flex al-start jc-start md-f-column md-gap-small">
                        <div className="icon d-flex al-center jc-center border-round">
                            {/* <AiOutlineUser /> */}
                            <img src={customer?.pictureUrl} style={{ height: '70px', width: '70px', objectFit: 'cover' }} alt="" />
                        </div>
                        <div>
                            <p className="title"><b>ข้อมูลลูกค้า</b></p>
                            <p>ชื่อ - นามสกุล : <b>{customer?.name}</b></p>
                            <p>Line Name : <b>{customer?.displayName}</b></p>
                            <p>uid : <b>{customer?.userId}</b></p>
                        </div>
                    </div>
                    <div className="d-flex al-start jc-start md-f-column md-gap-small">
                        <div className="icon d-flex al-center jc-center border-round">
                            <AiOutlineFileText />
                        </div>
                        <div>
                            <p className="title"><b>Note (สำหรับ Admin)</b></p>
                            <div className="long-text">{customer?.note}</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* table */}
            <div className="table">
                <p className="title">รายการสั่งซื้อล่าสุด</p>
                <table>
                    <thead>
                        <tr>
                            <th>หมายเลขคำสั่งซื้อ</th>
                            <th>วันที่</th>
                            <th>สถานะ</th>
                            <th>ยอดรวม</th>
                            <th>คำสั่ง</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lastOrder && lastOrder.length > 0 ?
                            lastOrder.map(order => (
                                <tr key={order.id}>
                                    <td>#{order?.id}</td>
                                    <td>{<Moment format="DD-MM-YYYY">{order?.createdAt}</Moment>}</td>
                                    <td>
                                        {order?.status === 1 ? (
                                            <span className="status-pending">กำลังดำเนินการ</span>
                                        ) : order?.status === 2 ? (
                                            <span className='status-complete'>เสร็จสิ้น</span>
                                        ) : (
                                            <span className="status-cancel">ยกเลิก</span>
                                        )}

                                    </td>
                                    <td>฿{formatNumber(order?.total)}</td>
                                    <td>
                                        <div className='actions'>
                                            <BsThreeDots onClick={(e) => { handleClickActionTable(e) }} />
                                            <div className="dropdown">
                                                <ul onClick={(e) => { handleClickActionTable(e) }} >
                                                    <li><Link to={"/admin/order/" + order.id} className='link'>รายละเอียด</Link></li>
                                                    <li><Link to={'/admin/update-order/' + order.id} className='link'>แก้ไข</Link></li>
                                                    <li className='link red-color' onClick={() => {
                                                        handleDelOrder(order.id)
                                                    }}>ลบ</li>
                                                </ul>
                                            </div>
                                        </div></td>
                                </tr>
                            )) :
                            <tr></tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default UserDetailAdmin